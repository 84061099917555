import React from "react"
import "./PlaceInLine.scss"
import { getOrdinalNumber } from "utils/format"
import { ReactComponent as Checkmark } from "./checkmark.svg"

const PlaceInLine = ({ placeInLine, state }) =>
  (
    state && state === "visited" ?
    <div className="place-in-line admitted">
      <Checkmark />
    </div>
    :
    <div className="place-in-line">
      <div className="number">
        { getOrdinalNumber(placeInLine) }
      </div>
      <div className="in-line-descriptor">
        in line
      </div>
    </div>
  )

export default PlaceInLine
