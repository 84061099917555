import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, Redirect } from "react-router-dom"
import { signInUser } from "api/auth"
import queryString from "query-string"
import { usePageView } from "utils/analytics"
import { Helmet } from "react-helmet"

import Auth from "routes/Auth"
import ResendConfirmation from "routes/auth/ResendConfirmation"
import Input from "components/Input"
import Button from "components/Button"
import Icon from "components/Icon"
import "./Auth.scss"

const SignInRoute = props => {

  // check if user is coming from a confirmation
  const search = props.location.search
  const qs = queryString.parse(search)
  const confirmation = qs.account_confirmation_success

  const dispatch = useDispatch()
  const { authenticated } = useSelector(
    state => state.auth
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [resendConfirmation, setResendConfirmation] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    const options = {
      data: {
        email,
        password
      }
    }
    
    setError(null)
    setResendConfirmation(false)
    setLoading(true)
    
    dispatch(signInUser(options)).then(() => {
    }).catch(error => {
      if (error.response) {
        const errorMsg = error.response.data.errors[0]

        // this is a hack to determine if the error is an unconfirmed account
        if(errorMsg.includes("confirmation email")){
          setResendConfirmation(true)
        }

        setError(errorMsg)
      }
      setLoading(false)
      setPassword("")
    })
  }

  usePageView("Sign In")

  if(authenticated) {
    return <Redirect to="/lobby" />
  }

  const origin = window.location.origin
  const href = `${origin}/sign_in`

  return (

    <Auth>
      <Helmet>
        <link rel="canonical" href={href} />
      </Helmet>
      <form onSubmit={handleSubmit} className="auth-form sign-in-form">

        { confirmation && 
          <div className="email-confirmed">
            <p>Thanks! Your email has been confirmed. Please sign in below.</p> 
          </div>
        }

        {error &&
          <div className="error-message">
            <Icon name="error" width="43" height="43" />
            {resendConfirmation  
              ? <ResendConfirmation error={error} email={email} />
              : <p>{error}</p>
            }
          </div>
        }

        <fieldset>
          <Input
            type="email"
            name="email"
            label="Email address"
            value={email}
            disabled={loading}
            onChange={e => setEmail(e.target.value)}
          />

          <Input
            type="password"
            name="password"
            label="Password"
            value={password}
            disabled={loading}
            onChange={e => setPassword(e.target.value)}
          />
        </fieldset>

        <Button
          label={loading ? "Signing in..." : "Sign in"}
          type="submit"
          loading={loading}
        />

      </form>
      <Link to="/forgot_password">Forgot Password</Link>
      <br />
      <Link 
          to="/sign_up"
          className="sign-in-link"
        >
          Create an account
        </Link>
    </Auth>
  )
}

export default SignInRoute
