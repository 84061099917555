import { createSlice } from "@reduxjs/toolkit"
import { removeAuthHeaders } from "utils/auth"
import { resetIdentity, identifyUser } from "utils/analytics"

const authSlice = createSlice({
  name: "auth",
  initialState: {
    currentUser: { account: {}},
    authenticated: false
  },
  reducers: {
    setCurrentUser: {
      reducer(state, action) {
        const { data } = action.payload
        identifyUser(data.id)
        state.currentUser = data
        state.authenticated = true
      }
    },
    signOutUser: {
      reducer(state, _) {
        removeAuthHeaders()
        resetIdentity()
        state.currentUser = {}
        state.authenticated = false
      }
    },
    setAccount: {
      reducer(state, action) {
        const { data } = action.payload
        state.currentUser.account = data
      },
    },
    setUserLobby: {
      reducer(state, action) {
        // no data wrapper
        state.currentUser.current_lobby_id = action.payload.id
        state.currentUser.current_lobby_name = action.payload.name
      }
    },
    setLobbies: {
      reducer(state, action) {
        state.currentUser.account.lobbies = action.payload
      }
    }
  }
})

export const { 
  setCurrentUser, 
  signOutUser, 
  setAccount, 
  setUserLobby,
  setLobbies
} = authSlice.actions

export default authSlice.reducer