import React from "react"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import styles from "./Button.module.scss"

const cx = classNames.bind(styles)

const Button = ({ type, className, label, onClick, loading, ...rest }) => (
  <button
    type={type}
    className={cx(styles.button, className)}
    onClick={onClick}
    disabled={loading}
    {...rest}
  >
    {label}
  </button>
)

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onClick: PropTypes.func,
  loading: PropTypes.bool,
}

Button.defaultProps = {
  type: "button",
  className: null,
  onClick: null,
  loading: false,
}

export default Button
