import React from "react"
import { useSelector } from "react-redux"
import { usePageView } from "utils/analytics"

import Header from "routes/settings/Header"
import Content from "routes/settings/Content"
import AccountForm from "components/account/Form"
import AccountManage from "components/account/Manage"
import "./Index.scss"

const AccountRoute = () => {

  const { currentUser } = useSelector(
    state => state.auth
  )

  const { account } = currentUser

  usePageView("Account")
  
  return (
    <div className="manage-account">
      <Header title="Manage account" />
      <Content>
        <AccountForm
          currentUser={currentUser}
          account={account}
        />
        <AccountManage />
      </Content>
    </div>
  )
}

export default AccountRoute
