import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useToasts } from 'react-toast-notifications'
import { updateAccount } from "api/billing"

import Input from "components/Input"
import Button from "components/Button"
import "./Form.scss"

const AccountForm = ({ currentUser, account }) => {

  const dispatch = useDispatch()
  const billingEmail = account.billing_email || currentUser.email

  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)
  const [name, setName] = useState(account.name)
  const [email, setEmail] = useState(billingEmail)
  const [errors, setErrors] = useState({})

  const handleSubmit = async(e) => {
    e.preventDefault()

    resetErrors()
    setLoading(true)
    

    const params = {
      account: {
        name,
        billing_email: email,
      }
    }
    
    // Create the subscription
    dispatch(updateAccount(params)).then(() => {
      setLoading(false)
      addToast("Billing info updated!", { appearance: "success" })
    }).catch(error => {
      setErrors(error.response.data)
      setLoading(false)
    })
  }
  
  const resetErrors = () => {
    setErrors({})
  }

  const getErrors = name => {
    return errors[name] || []
  }

  return (
    <div className="account-form">
      <form onSubmit={handleSubmit}>

        <fieldset>
          <Input
            type="text"
            name="name"
            label="Business name"
            value={name}
            disabled={loading}
            error={getErrors("name")}
            onChange={e => setName(e.target.value)}
          />

          <Input
            type="text"
            name="email"
            label="Billing email address"
            value={email}
            disabled={loading}
            error={getErrors("email")}
            onChange={e => setEmail(e.target.value)}
          />

        </fieldset>

        <Button
          className="submit"
          label="Update"
          type="submit"
          loading={loading}
        />

      </form>
    </div>
  )
}

export default AccountForm