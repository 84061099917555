import React, { useState } from "react"
import { useSelector } from "react-redux"
import classNames from "classnames"

import Logo from "components/Logo"
import Icon from "components/Icon"
import MobileNav from "components/menu/MobileNav"
import "./Header.scss"

const Header = () => {

  const { currentUser } = useSelector(
    state => state.auth
  )

  const { current } = useSelector(
    state => state.lobbies
  )

  const lobby = current

  const [showNav, setShowNav] = useState(false)

  const toggleNav = () => setShowNav(!showNav)

  const headerClass = classNames("header", { "nav-open": showNav })

  return (
    <header className={headerClass}>
      <div className="header-content">
        <div />
        <p className="header-title">
          <Icon name="location" width="14" height="17" />
          <span>{currentUser.current_lobby_name}</span>
        </p>
        <div className="header-logo">
          <Logo className="mobile-header" />
        </div>
        <button 
          type="button" 
          className="icon-button"
          onClick={toggleNav}
        >
          <Icon 
            name="chevron-down" 
            width="24" 
            height="24" 
          />
        </button>
      </div>
      <MobileNav lobby={lobby} />
    </header>
  )
}

export default Header
