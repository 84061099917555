import React from "react"
import Logo from "components/Logo"
import "./Footer.scss"

const Footer = () => (
  <footer>
    <p>Powered by</p>
    <a href="https://awaiting.app" target="_blank" rel="noopener noreferrer">
      <Logo />
    </a>
  </footer>  
)

export default Footer
