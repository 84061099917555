const authHeaderKeys = [
  "access-token",
  "client",
  "expiry",
  "uid",
]

const authTokenPresent = () => {
  return Boolean(window.localStorage.getItem(authHeaderKeys[0]))
}

const persistAuthHeaders = headers => {
  authHeaderKeys.forEach(key => {
    if(headers[key]) {
      window.localStorage.setItem(key, headers[key])
    }
  })
}

const removeAuthHeaders = () => {
  authHeaderKeys.forEach(key => {
    window.localStorage.removeItem(key)
  })
}

const apiHeaders = () => {
  let headers = {}
  authHeaderKeys.forEach(key => {
    if(window.localStorage.getItem(key)) {
      headers[key] = window.localStorage.getItem(key)
    }
  })
  return headers
}

const authQueryString = () => {
  let queryString = ""
  authHeaderKeys.forEach(key => {
    queryString += `${key}=${window.localStorage.getItem(key)}&`
  })
  return queryString
}

export { 
  authTokenPresent,
  persistAuthHeaders, 
  removeAuthHeaders, 
  apiHeaders,
  authQueryString
}