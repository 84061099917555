import React from "react"
import PropTypes from "prop-types"

import "./Count.scss"

const VisitsCount = ({ count }) => {

  const HIGHLIGHT = "#00bff3"
  const BACKGROUND = "transparent"
  const EMPTY = "#757575";

  const backgroundSegment = count <= 1 ? 0 : Math.min((100 / (count * 3)), 4) 
  const blueSegment = (100 - ((count) * backgroundSegment)) / count

  let conicGradient = count > 0 
    ? `conic-gradient(${BACKGROUND} ${backgroundSegment/2}%,` 
    : `conic-gradient(${EMPTY} 0% 100%)`

  const segments = [...Array(count).keys()]

  segments.forEach(i => {
    const startPercentage = (backgroundSegment/2 * i) + (i+1) * (backgroundSegment/2) + i * blueSegment
    const endPercentage = startPercentage + blueSegment
    const nextPercentage = Math.min(endPercentage + backgroundSegment, 100)
    conicGradient += `
      ${HIGHLIGHT} ${startPercentage}% ${endPercentage}%, 
      ${BACKGROUND} ${endPercentage}% ${nextPercentage}%
    `
    conicGradient += i + 1 < count ? "," : ")"
  })

  const borderStyle = {
    background: conicGradient
  }

  const countClass = count > 0 ? "" : "empty"

  return (
    <div className="visits-count" style={borderStyle}>
      <span className={countClass}>{count}</span>
    </div>
  )
}

VisitsCount.propTypes = {
  count: PropTypes.number.isRequired
}

export default VisitsCount