import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { admitVisitor } from "api/visits"
import classNames from "classnames"

import Button from "components/Button"
import "./InviteNextButton.scss"

const InviteNextButton = () => {

  const dispatch = useDispatch()
  const { current } = useSelector(
    state => state.lobbies
  )
  const { visits } = current
  
  const visitorsStillWaiting = visits.filter(v => v.state === "waiting")
  const visitorCount = visitorsStillWaiting.length
  const visitId = visitorsStillWaiting[0] && visitorsStillWaiting[0].id

  const admitFirstVisitor = e => {
    e.preventDefault()
    dispatch(admitVisitor(visitId))
  }

  const LobbyInviteText = () => {

    const people = visitorCount > 1 || visitorCount === 0 ? `${visitorCount} people are` : `1 person is`

    return (
      <div>
        <strong>Invite next visitor</strong>
        <span>{`${people} waiting`}</span>
      </div> 
    )
  }

  const buttonStyle = classNames("invite-button", { empty: visitorCount === 0 })

  return (
    <form onSubmit={admitFirstVisitor}>
      <Button
        className={buttonStyle}
        label={<LobbyInviteText />}
        type="submit"
        disabled={visitorCount === 0}
      />
    </form>
  )
}

export default InviteNextButton