import React from "react"

import "./RoleManager.scss"

const RoleManager = ({ 
  editable,
  lobbies, 
  role, 
  handleRoleChange,
  currentLobbyId,
  handleCurrentLobbyChange
 }) => {

  const changeRole = role => {
    if(!editable) return
    handleRoleChange(role)
  }
  
  return (
    <div className={`role-manager ${!editable ? "disabled" : ""}`}>
      <label>Role</label>
      <div className="role-selector">
        <div 
          className={`role ${role === "admin" && "selected"}`}
          onClick={() => changeRole("admin")}
        >
          <h4>Admin</h4>
          <p>Can manage waitlists</p>
          <p>Can manage users</p>
          <p>Can manage billing info</p>
        </div>
        <div 
          className={`role ${role === "operator" && "selected"}`}
          onClick={() => changeRole("operator")}
        >
          <h4>Operator</h4>
          <p>Can manage one waitlist</p>
        </div>
      </div>
      {lobbies.length > 1 &&
        <div className="field">
          <div className={`lobby-list ${role === "admin" && "hide"}`}>
            <p>Which waitlist will this operator manage?</p>
            {lobbies.map(lobby => 
              <label key={lobby.id}>
                <input
                  className="checkbox"
                  type="radio"
                  name="current_lobby_id"
                  value={lobby.id}
                  checked={lobby.id === currentLobbyId}
                  onChange={() => handleCurrentLobbyChange(lobby.id)}
                />
                {lobby.name}
              </label>
            )}
          </div>
        </div>
      }
    </div>

  )
}

export default RoleManager
