import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ActionCableProvider } from "@thrash-industries/react-actioncable-provider"
import { ActionCableConsumer } from "@thrash-industries/react-actioncable-provider"
import debounce from "lodash/debounce"
// import { authQueryString } from "utils/auth"
import { getLobby } from "api/lobbies"
import { addVisit, removeVisit } from "reducers/lobbies"

import Sidebar from "components/Sidebar"
import "./Main.scss"

const MainRoute = ({ children, showVisitor }) => {

  const dispatch = useDispatch()
  const { currentUser } = useSelector(
    state => state.auth
  )  

  // this unsubcribes the channel, even if the qs is not used on the api side
  // const qs = authQueryString()
  // const API_WS_ROOT = `${process.env.REACT_APP_API_WS_ROOT}?${qs}`
  const API_WS_ROOT = process.env.REACT_APP_API_WS_ROOT

  const lobbyId = currentUser.current_lobby_id

  const handleReceive = response => {
    if(response.state === "removed"){
      dispatch(removeVisit(response.id))
    } else {
      dispatch(addVisit(response))
    }
  }

  useEffect(() => {
    // not sure best place for this
    const setWindowHeight = debounce(() => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }, 500)

    const fetchLobby = () => dispatch(getLobby(lobbyId))
    const refreshLobby = debounce(() => {
      dispatch(getLobby(lobbyId, false))
    }, 500)

    fetchLobby()
    setWindowHeight()
    document.body.classList.add("no-scroll")
    window.addEventListener("focus", refreshLobby)
    window.addEventListener("resize", setWindowHeight)
    
    return () => {
      document.body.classList.remove("no-scroll")
      window.removeEventListener("focus", refreshLobby)
      window.removeEventListener("resize", setWindowHeight)
    }
  }, [lobbyId, dispatch])

  return (
    <main>
      <ActionCableProvider url={API_WS_ROOT}>
        <div className={`content ${showVisitor && "shrunk"}`}>
          {children}
          <ActionCableConsumer
            channel={{ channel: "VisitsChannel", lobby: lobbyId }}
            onReceived={handleReceive}
          />
        </div>
        <Sidebar />
      </ActionCableProvider>
    </main>
  )
}

export default MainRoute
