import apiCall from "utils/api"
import { setCurrentUser, signOutUser } from "reducers/auth"

export const validateToken = () => async dispatch => {
  return apiCall("get", `auth/validate_token`)
    .then(response => {
      const user = response.data
      dispatch(setCurrentUser(user))
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}

export const signInUser = options => async dispatch => {
  return apiCall("post", `auth/sign_in`, options)
    .then(response => {
      const user = response.data
      dispatch(setCurrentUser(user))
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}

export const registerUser = params => async dispatch => {
  const options = { data: params }
  return apiCall("post", `auth`, options)
    .then(response => {
      const user = response.data
      dispatch(setCurrentUser(user))
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}

export const resetPassword = options => async() => {
  return apiCall("post", `auth/password`, options)
    .then(response => {
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}

export const updatePassword = options => async dispatch => {
  return apiCall("put", `auth/password`, options)
    .then(response => {
      const user = response.data
      dispatch(setCurrentUser(user))
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}

export const resendConfirmation = options => async dispatch => {
  return apiCall("post", `auth/confirmation`, options)
    .then(response => {
      dispatch(signOutUser())
      return response
    }).catch(error => {
      dispatch(signOutUser())
      console.error(error.message)
      throw error
    }) 
}

export const signOut = () => async dispatch => {
  return apiCall("delete", `auth/sign_out`)
    .then(response => {
      dispatch(signOutUser())
      return response
    }).catch(error => {
      dispatch(signOutUser())
      console.error(error.message)
      throw error
    })
}
