import React, { useState, useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { ActionCableConsumer } from "@thrash-industries/react-actioncable-provider"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { getVisitor, readMessages } from "api/visitors"
import { usePageView } from "utils/analytics"
import { capitalize } from "utils/format"

import VisitorHeader from "components/visitors/Header"
import VisitCardActions from "components/visits/card/Actions"
import Icon from "components/Icon"
import Message from "components/Message"
import "./Detail.scss"

const VisitorDetail = ({ active, visitorId }) => {

  const messagesEndRef = useRef(null)
  const timer1Ref = useRef(null)
  const timer2Ref = useRef(null)

  dayjs.extend(localizedFormat)

  const [visitor, setVisitor] = useState({ visits: []})
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()

  const scrollToBottom = () => {
    timer1Ref.current = setTimeout(() => {
      messagesEndRef.current.scrollIntoView()
    }, 1)
  }

  const smoothScrollToBottom = wait => {
    timer2Ref.current = setTimeout(() => {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }, wait)
  }

  useEffect(() => {
    if(!active) setLoading(true)
    
    if(active) {
      dispatch(getVisitor(visitorId)).then(visitor => {
        setVisitor(visitor.data)
        scrollToBottom()
        smoothScrollToBottom(400)
        setLoading(false)
      })
      dispatch(readMessages(visitorId))
    }
    return () => {
      clearTimeout(timer1Ref.current)
      clearTimeout(timer2Ref.current)
    }
  }, [active, dispatch, visitorId])

  usePageView("Visitor Route")

  const handleReceive = response => {
    setVisitor(response)
    smoothScrollToBottom(1)
    if(active) {
      dispatch(readMessages(visitorId))
    }
  }

  const { visits } = visitor
  const lastVisit = visits[visits?.length - 1]
  
  return (
    <div className={`visitor-detail ${active ? "active" : ""}`}>
      <div className="visitor-content">
        {!loading &&
          <>
          <ActionCableConsumer
            channel={{ channel: "VisitorsChannel", visitor: visitorId }}
            onReceived={handleReceive}
          />
          <VisitorHeader visitor={visitor} />
          <ul className="visitor-visits">
            {visits.map(visit => 
              <li key={visit.id} className="visitor-visit">
                <div className="visit-header">
                  <div>
                    <h5>{dayjs(visit.created_at).format("llll")}</h5>
                    <h5 className="muted">{visit.day_number}</h5>
                  </div>
                  <div>
                    <h5>{capitalize(visit.state)}</h5>
                    <h5 className="waited">
                      <Icon name="clock" width="13" height="13" />
                      {Math.round(visit.time_waited/60)}m
                    </h5>
                  </div>
                </div>
                <div className="visitor-messages">
                  {visit.messages.map(message => 
                    <Message 
                      key={message.id}
                      message={message}
                    />
                  )}
                </div>
              </li>
            )}
            <div ref={messagesEndRef} />
          </ul>
          <div className="visitor-footer">
            <VisitCardActions visit={lastVisit} />
          </div>
          </>
        }
      </div>
    </div>
  )
}

export default VisitorDetail
