import apiCall from "utils/api"
import { removeVisit } from "reducers/lobbies"
import { setVisit } from "reducers/customer"

export const getVisit = visitId => async dispatch => {
  return apiCall('get', `customer/visits/${visitId}`)
    .then(response => {
      dispatch(setVisit(response.data))
      return response
    }).catch(error => {
      throw error
    })
}

export const cancelVisit = visitId => async dispatch => {
  return apiCall('delete', `customer/visits/${visitId}`)
    .then(response => {
      dispatch(setVisit(response.data))
      return response
    }).catch(error => {
      throw error
    })
}

export const admitVisitor = visitId => async dispatch =>{
  return apiCall("post", `visits/${visitId}/admit`)
    .then(response => {
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}

export const removeVisitor = visitId => async dispatch => {
  dispatch(removeVisit(visitId))
  return apiCall("post", `visits/${visitId}/remove`)
    .then(response => {
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}
