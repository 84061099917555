import apiCall from "utils/api"
import { setLobbies } from "reducers/auth"
import { 
  getLobbyStart,
  getLobbySuccess, 
  setLobby
} from "reducers/lobbies"
import { setUserLobby } from "reducers/auth"
import { updateAccountLobby } from "reducers/account"

export const getLobbies = () => async dispatch => {
  return apiCall("get", `lobbies`)
    .then(response => {
      dispatch(setLobbies(response.data))
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}

export const getLobby = (lobbyId, showLoading=true) => async dispatch => {
  if(showLoading) {
    dispatch(getLobbyStart())
  }
  return apiCall("get", `lobbies/${lobbyId}`)
    .then(response => {
      dispatch(setLobby(response.data))
      dispatch(getLobbySuccess())
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}

export const createLobby = options => async dispatch => {
  return apiCall("post", `lobbies`, options)
    .then(response => {
      dispatch(setLobby(response.data))
      dispatch(setUserLobby(response.data))
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}

export const updateLobby = (lobbyId, options) => async dispatch => {
  return apiCall("patch", `lobbies/${lobbyId}`, options)
    .then(response => {
      dispatch(updateAccountLobby(response.data))
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}

export const getLobbyVisits = (lobbyId, startDate, endDate) => async () => {
  const options = {
    params: {
      start_date: startDate,
      end_date: endDate
    }
  }
  return apiCall("get", `lobbies/${lobbyId}/visits`, options)
    .then(response => {
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}