import { createSlice } from "@reduxjs/toolkit"

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    visitLoading: true
  },
  reducers: {
    setVisitLoading(state, action) {
      return {...state, visitLoading: true}
    },
    setVisitLoaded(state, action) {
      return {...state, visitLoading: false}
    }
  }
})


export const { 
  setVisitLoading,
  setVisitLoaded
} = uiSlice.actions

export default uiSlice.reducer
