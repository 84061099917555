import React from "react"
import classNames from "classnames"

import { ReactComponent as AwaitingLogo } from "assets/awaiting-logo-white.svg"
import "./Logo.scss"

const Logo = ({ className }) => {
  
  const logoClass = classNames("awaiting-logo", className)
  
  return <AwaitingLogo className={logoClass} />
}

export default Logo
