import apiCall from "utils/api"

export const getLobbyVisits = (lobbyId, startDate, endDate) => async () => {
  const options = {
    params: {
      start_date: startDate,
      end_date: endDate
    }
  }
  return apiCall("get", `reports/lobbies/${lobbyId}/visits`, options)
    .then(response => {
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}