import React from "react"
import SupportButton  from "components/SupportButton"

import bench from "assets/bench.svg"
import "./Closed.scss"

const ClosedLobby = () => {

  return (
    <div className="expired-lobby">
      <div className="instructions">
        <h3>Your account is no longer active :(</h3>
        <p>To continue using Awaiting, please contact support to activate your account.</p>
        <SupportButton
            label="Contact Support"
          />
      </div>
    
      <div className="expired-lobby-image">
        <img src={bench} alt="Trial expired" />
      </div>
    </div>
  )
}

export default ClosedLobby
