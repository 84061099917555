import React from "react"
import Icon from "components/Icon"
import WaitTime from "components/visits/WaitTime"

import "./Content.scss"

const VisitCardContent = ({ visit, highlighted }) => {

  const { number, name, phone_number, unread_messages } = visit

  const showMessageCount = !highlighted && unread_messages > 0 

  return (
    <div className="visit-card-content">
      <div className="visit-top">
        <div className="visit-number">
          <span>Visitor {number}</span>
        </div>
        <div className="visit-time">
          <WaitTime visitTime={visit.joined_at} />
        </div>
      </div>
      <div className="visit-name">
        <span>{name}</span>
        {showMessageCount &&
          <span className="unread-messages">{unread_messages}</span>
        }
      </div>
      <div className="visit-bottom">
        <div className="visit-number tablet-only">
          <span>Visitor {number}</span>
        </div>
        <div className="visit-phone">
          <Icon name="phone" width="13" height="13" />
          {phone_number}
        </div>
        <div className="visit-time tablet-only">
          <WaitTime visitTime={visit.joined_at} />
        </div>
      </div>
    </div>
  )
}

export default VisitCardContent
