import React from "react"

import Logo from "components/Logo"
import "./Auth.scss"

const AuthRoute = props => (

  <div className={`auth-page ${props.className}`}>
    <div className="auth-logo">
      <a href="https://awaiting.app">
        <Logo />
      </a>
    </div>
    {props.children}
  </div>
)


export default AuthRoute
