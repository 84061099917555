import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAccount } from "api/account"
import { usePageView } from "utils/analytics"

import Header from "routes/settings/Header"
import Content from "routes/settings/Content"
import List from "components/users/List"
import NewButton from "components/settings/NewButton"
import "./Index.scss"

const UsersRoute = () => {

  const dispatch = useDispatch()

  const { users } = useSelector(
    state => state.account
  )

  useEffect(() => {
    dispatch(getAccount()).then(() => {
    }).catch(error => console.error(error.message))
  }, [dispatch])

  usePageView("Manage Operators")
  
  return (
    <div className="manage-users">
      <Header title="Manage users" />
      <Content>
        <List users={users} />
        <NewButton to="users/invite" text="Invite new user" />
      </Content>
    </div>
    
  )
}

export default UsersRoute
