import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import updateLocale from "dayjs/plugin/updateLocale"

import Icon from "components/Icon"

const WaitTime = ({ visitTime }) => {

  const config = {
    thresholds: [
      { l: 's', r: 1 },
      { l: 'm', r: 1 },
      { l: 'mm', r: 59, d: 'minute' },
      { l: 'h', r: 1 },
      { l: 'hh', r: 23, d: 'hour' },
      { l: 'd', r: 1 },
      { l: 'dd', r: 29, d: 'day' },
      { l: 'M', r: 1 },
      { l: 'MM', r: 11, d: 'month' },
      { l: 'y' },
      { l: 'yy', d: 'year' }
    ],
  }

  dayjs.extend(relativeTime, config)
  dayjs.extend(updateLocale)

  // had this is a utility file but wasn't working
  // will try to move it in the future
  dayjs.updateLocale('en', {
    relativeTime: {
      future: '%s',
      past: '%s',
      s:  '0m',
      ss: '%ss',
      m:  '%dm',
      mm: '%dm',
      h:  '%dh',
      hh: '%dh',
      d:  'a day',
      dd: '%dd',
      M:  'a month',
      MM: '%dM',
      y:  'a year',
      yy: '%dY'
    }
  })

  const [waitTime, setWaitTime] = useState(dayjs(visitTime).fromNow())

  useEffect(() => {
    const calculateWaitTime = () => dayjs(visitTime).fromNow()
    const interval = setInterval(() => {
      setWaitTime(calculateWaitTime())
    }, 15000)

    return () => {
      if(interval){
        clearInterval(interval)
      }
    }
  }, [visitTime])
  
  return(
    <>
      <Icon name="clock" width="13" height="13" />
      {waitTime}
    </>
  )
}

WaitTime.propTypes = {
  visitTime: PropTypes.string.isRequired
}

export default WaitTime