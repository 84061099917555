import { combineReducers } from "redux"
import accountReducer from "./account"
import authReducer from "./auth"
import lobbiesReducer from "./lobbies"
import customerReducer from "./customer"
import uiReducer from "./ui"

const appReducer = combineReducers({
  account: accountReducer,
  auth: authReducer,
  lobbies: lobbiesReducer,
  customer: customerReducer,
  ui: uiReducer
})

const rootReducer = (state, action) => {
  if (action.type === "auth/signOutUser") {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer
