import React from "react"

import { useDispatch } from "react-redux"
import { manageBilling } from "api/billing"
import Button from "components/Button"

const StripeLink = () =>  {
  
  const dispatch = useDispatch()

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(manageBilling()).then(response => {
      window.location = `${response.data.url}`
    }).catch(error => {
      console.error(error)
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Button 
        type="submit"
        label="View or Change Billing Information ->"
      />
    </form>

  )
}

export default StripeLink