import React from "react"
import { Link } from "react-router-dom"
import pluralize from "pluralize"

import Icon from "components/Icon"
import "./Header.scss"

const VisitorHeader = ({ visitor }) => {

  const { name, phone_number, num_visits, average_wait } = visitor

  return (
    <div className="visitor-header">
      <div>
        <div className="visitor-name">
          <Link to="/lobby" className="visitor-close">
            <Icon name="close" width="14" height="14" /> 
          </Link>
          <span>{name}</span>
        </div>
        <div className="visitor-phone">
          <Icon name="phone" width="13" height="13" />
          {phone_number}
        </div>
      </div>
      <div>
        <p className="visitor-count">{pluralize("Visit", num_visits, true)}</p>
        <p className="visitor-wait">
          {`Avg Wait: ${Math.round(average_wait/60)}m`}
        </p>
      </div>
    </div>
  )
}

export default VisitorHeader
