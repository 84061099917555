import React from "react"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import styles from "./Checkbox.module.scss"

const cx = classNames.bind(styles)

const Checkbox = React.forwardRef(
  (
    {
      name,
      label,
      checked,
      onChange,
      wrapperClass,
      ...rest
    },
    ref
  ) => (
    <div className={cx(styles.checkbox, wrapperClass)}>
      <label className={styles.label} htmlFor={name}>
        <input
          type="checkbox"
          id={name}
          name={name}
          checked={checked}
          onChange={onChange}
          ref={ref}
          {...rest}
        />
        {label}
      </label>
    </div>
  )
)

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

Checkbox.defaultProps = {
  className: null,
}

export default Checkbox
