import React from "react"
import { Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import { usePageView } from "utils/analytics"

import Main from "routes/Main"
import VisitorDetail from "components/visitors/Detail"
import Header from "components/Header"
import VisitCard from "components/visits/Card"
import EmptyLobby from "components/lobbies/Empty"
import ClosedLobby from "components/lobbies/Closed"
import InviteNextButton from "components/InviteNextButton"
import "./Lobby.scss"

const LobbyRoute = ({ match }) => {

  const path = match.path
  const isVisitorPath = path.match(/visitors/)
  const visitorId = match.params.id
  const showVisitor = Boolean(visitorId && isVisitorPath)

  const { currentUser } = useSelector(
    state => state.auth
  )
  const { account } = currentUser
  const { status } = account
  
  const { current, loading } = useSelector(
    state => state.lobbies
  
  )
  const lobby = current
 
  const visitCards = lobby.visits.map(visit => 
    <CSSTransition
      key={visit.id}
      timeout={250}
      classNames="visit"
    >
      <VisitCard 
        visit={visit} 
        highlighted={visit.visitor_id === visitorId}  
        darkened={showVisitor && visit.visitor_id !== visitorId}
      />
    </CSSTransition>
  )

  const { visits } = lobby
  const visitorCount = visits.length

  const active = status === "active" || status === "trialing"

  usePageView("Lobby")

  if(!currentUser.current_lobby_id) {
    return <Redirect to="/lobbies/new" />
  }

  return (
    <Main showVisitor={showVisitor}>
      <div className="lobby">
        <Header />
        {loading
          ? <div className="loading" />
          : <>
              {active      
                ? <>
                    {visitorCount > 0
                      ? <TransitionGroup className="lobby-visits">
                          {visitCards}
                        </TransitionGroup>
                      : <EmptyLobby />
                    }
                  </>
                : <ClosedLobby />
              }
              <>
              {active &&
                <div className="mobile-invite">
                  <InviteNextButton />
                </div>
              }
            </> 
              <div className="mobile-invite">
                <InviteNextButton />
              </div>
            </>
        }
        <VisitorDetail
          active={showVisitor} 
          visitorId={visitorId} />
      </div>
    </Main>
  )
}

export default LobbyRoute
