import React, { useState, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { validateToken } from "api/auth"
import { persistAuthHeaders } from "utils/auth"
import queryString from "query-string"

import "./Confirmed.scss"

// User comes here after clicking the confirmation email
// User should be signed in so the auth parameters should be in the query string

const ConfirmedRoute = props => {

  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState(false)
  
  const dispatch = useDispatch()
  const { authenticated } = useSelector(
    state => state.auth
  )
  
  const { location } = props
  const search = location.search
  const qs = useMemo(() => queryString.parse(search), [search])

  useEffect(() => {

    // critical that this only runs once as we can't have the 
    // auth headers overwritten after the validate response

    const { token, client, expiry, uid } = qs
    const headers = {
      "access-token": token,
      "client": client,
      "expiry": expiry,
      "uid": uid
    }

    persistAuthHeaders(headers)
    dispatch(validateToken()).then(() => {}
    ).catch(() => {
      setLoading(false)
      setErrors(true)
    })
  }, [dispatch, qs])

  if(!qs.token || !qs.uid) {
    return <Redirect to="/sign-up" />
  }

  if(authenticated) {
    return <Redirect to="/lobbies/new" />
  }

  return (
    <div className="confirmed">
      { loading && <p>One moment...</p> }
      { errors && 
        <>
          <p>Something went wrong.</p> 
          <p>Please contact support at <a href="mailto:support@awaiting.app">support@awaiting.app</a>.</p>
        </>
      }
    </div>
  )
}

export default ConfirmedRoute
