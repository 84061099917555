import React, { useState } from "react"

import Input from "components/Input"
import Button from "components/Button"
import "./CreateForm.scss"

const CreateLobbyForm = ({ 
  loading,
  errors,
  handleSubmit,
  showWarning,
}) => {

  const [name, setName] = useState("")
  const [areaCode, setAreaCode] = useState("")

  const prepareData = e => {
    e.preventDefault()
    const params = {
      area_code: areaCode,
      name
    }

    handleSubmit(params)
  }

  const getErrors = name => {
    return errors[name] || []
  }

  return (

    <form onSubmit={prepareData} className="lobby-form">

      <fieldset>
        <Input
          type="text"
          name="area_code"
          label="What area code would you like for your waitlist SMS number?"
          value={areaCode}
          className="area-code"
          length="3"
          maxLength="3"
          disabled={loading}
          error={getErrors("area_code")}
          required
          autoFocus={true}
          onChange={e => setAreaCode(e.target.value)}
        />
        
        <Input
          type="text"
          name="name"
          label="Waitlist name"
          value={name}
          disabled={loading}
          error={getErrors("name")}
          onChange={e => setName(e.target.value)}
        />
      </fieldset>

      {showWarning && 
        <p className="billing-warning">
          Creating a new waitlist will increase your subscription and 
          increase your account’s monthly capacity. See our pricing or 
          contact sales for more information.
        </p>
      }

      <Button
        className="submit"
        label="Create waitlist"
        type="submit"
        loading={loading}
      />

    </form>
  )
}

export default CreateLobbyForm
