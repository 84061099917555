import React from "react"
import SupportButton  from "components/SupportButton"
import Icon from "components/Icon"

import desk from "assets/desk.svg"
import "./Pitch.scss"

const ReportsPitch = () => {
  const toggleMenu = () => {
    const sidebar = document.getElementsByClassName("reports-sidebar")[0]
    sidebar.classList.toggle("open")
  }
  
  return (
    <div className="reports-pitch">
      <div className="instructions">
        <div className="pitch-header">
          <div />
          <h3>Upgrade to see reports</h3>
          <div className="mobile-toggle" onClick={toggleMenu}>
            <Icon name="menu" width="32" height="32" />
          </div>
        </div>
        <p>Interested in a detailed visitor history, average wait times, 
          and more analysis on your business traffic?</p>
        <p>Contact us to upgrade your account to the Business plan.</p>
        <SupportButton
          label="Contact Support"
        />
      </div>
    
      <div className="reports-pitch-image">
        <img src={desk} alt="Trial expired" />
      </div>
    </div>
  )
}

export default ReportsPitch
