import React from "react"
import { Line } from "react-chartjs-2"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

const options = {
  scales: {
    x: {
      offset: true,
      ticks: {
        color: "#c1cddb",
      }
    },
    y: {
      min: 0,
      ticks: {
        color: "#c1cddb",
        stepSize: 1
      }
    },
  },
  plugins: {   
    legend: {
      display: false
    },
  }
}

const DailyChart = ({ raw }) => {

  dayjs.extend(utc)

  const days = raw.map(datum => dayjs(datum.step).utc().format("MMM D"))
  const visits = raw.map(datum => datum.visits)

  const data = {
    labels: days,
    datasets: [
      {
        label: "Visits",
        data: visits,
        fill: false,
        backgroundColor: "rgb(0, 191, 243)",
        borderColor: "rgba(0, 191, 243, 1)",
      },
    ],
  }

  const chartHeight = window.innerWidth > 991 ? 60 : 180

  return (
    <>
      <div className="header">
      </div>
      <Line 
        data={data} 
        options={options} 
        height={chartHeight}  
      />
    </>
  )
}

export default DailyChart