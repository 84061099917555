import apiCall from "utils/api"
import { markMessagesAsRead } from "reducers/lobbies"

export const getVisitor = visitorId => async dispatch => {
  return apiCall('get', `visitors/${visitorId}`)
    .then(response => {
      return response
    }).catch(error => {
      throw error
    })
}

export const readMessages = visitorId => async dispatch => {
  return apiCall('put', `visitors/${visitorId}/messages`)
    .then(response => {
      dispatch(markMessagesAsRead(visitorId))
      return response
    }).catch(error => {
      throw error
    })
}
