import React, { useEffect, useState } from "react"
import classNames from "classnames"

import Icon from "components/Icon"
import "./CustomToast.scss"

const CustomToast = ({ children, ...props }) => {
  
  const elementRef = React.createRef()
  const { onDismiss, placement, transitionState, transitionDuration } = props

  const [height, setHeight] = useState("auto")

  useEffect(
    () => {
      if (transitionState === "entered") {
        const el = elementRef.current;
        setHeight(el.offsetHeight + 2);
      }
      if (transitionState === "exiting") {
        setHeight(0)
      }
    },
    [elementRef, transitionState]
  );

  function getTranslate(placement) {
    const pos = placement.split("-");
    const relevantPlacement = pos[1] === "center" ? pos[0] : pos[1];
    const translateMap = {
      right: "translate3d(120%, 0, 0)",
      left: "translate3d(-120%, 0, 0)",
      bottom: "translate3d(0, 120%, 0)",
      top: "translate3d(0, -120%, 0)",
    };

    return translateMap[relevantPlacement];
  }
  
  const toastStates = placement => ({
    entering: { transform: getTranslate(placement) },
    entered: { transform: "translate3d(0,0,0)" },
    exiting: { transform: "scale(0.66)", opacity: 0 },
    exited: { transform: "scale(0.66)", opacity: 0 },
  })
  
  const toastClass = classNames("custom-toast", props.appearance)

  return (
    <div
      className="custom-toast-wrapper"
      ref={elementRef}
      style={{ height, transition: `height ${transitionDuration - 100}ms 100ms` }}
    >
      <div 
        className={toastClass}
        style={{
          ...toastStates(placement)[transitionState],
        }}
      >
        <div>
          <Icon 
            className="toast-icon"
            name="toast-success" 
            width="17" 
            height="15"
          />
          <span>{children}</span>
        </div>
        <Icon 
          className="toast-close" 
          name="toast-close" 
          width="15" 
          height="15"
          onClick={onDismiss}
        />
      </div>
      <div className="custom-toast-overlay" />
    </div>
  )
}

export default CustomToast