import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import { validateToken } from "api/auth"
import { authTokenPresent } from "utils/auth"

import PrivateRoute from "routes/Private"
import SignInRoute from "routes/SignIn"
import SignUpRoute from "routes/SignUp"
import NewLobbyRoute from "routes/lobbies/New"
import ForgotPasswordRoute from "routes/ForgotPassword"
import ResetPasswordRoute from "routes/ResetPassword"
import ConfirmedRoute from "routes/Confirmed"
import LobbyRoute from "routes/Lobby"
import ReportsRoute from "routes/Reports"
import SettingsRoute from "routes/Settings"
import JoinRoute from "routes/Join"
import VisitRoute from "routes/Visit"
import "./App.scss"

const App = () => {

  const dispatch = useDispatch()
  const { currentUser } = useSelector(
    state => state.auth
  )

  const { role } = currentUser

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if(authTokenPresent()) {
      dispatch(validateToken()).then(() => {
        setLoading(false)
      }).catch(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [dispatch])

  return (
    <>
      {loading
        ? <div className="loading" />
        : <Router>
            <Switch>
              <Route exact path="/sign_up" component={SignUpRoute} />
              <Route exact path="/sign_in" component={SignInRoute} />
              <Route exact path="/forgot_password" component={ForgotPasswordRoute} />
              <Route exact path="/reset_password" component={ResetPasswordRoute} />
              <Route exact path="/confirmed" component={ConfirmedRoute} />
              <Route exact path="/v/:uuid" component={VisitRoute} />
              <PrivateRoute exact path="/lobby" component={LobbyRoute} />
              <PrivateRoute exact path="/lobbies/new" component={NewLobbyRoute} />
              <PrivateRoute exact path="/visitors/:id" component={LobbyRoute} />
              <PrivateRoute exact path="/reports" component={ReportsRoute} />
              {role === "admin" &&
                <PrivateRoute path="/settings" component={SettingsRoute} />
              }
              <Route exact path="/join/:token" component={JoinRoute} />
              <Redirect to="/sign_in" />
            </Switch>
          </Router>
      }
    </>
  )
}

export default App
