import React from "react"
import classNames from "classnames/bind"
import PropTypes from "prop-types"

import FormError from "components/FormError"
import styles from "./Input.module.scss"

const cx = classNames.bind(styles)

const Input = React.forwardRef(
  (
    {
      type,
      name,
      label,
      value,
      onChange,
      className,
      disabled,
      pattern,
      minLength,
      autoComplete,
      required,
      autoFocus,
      error,
      ...rest
    },
    ref
  ) => (
    <div className={cx(styles.textInput, className, { hasErrors: error.length > 0 })}>
      <label htmlFor={name}>{label}</label>
      <div className={styles.maskWrap}>
        <input
          id={name}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          pattern={pattern}
          minLength={minLength}
          autoComplete={autoComplete}
          required={required}
          autoFocus={autoFocus}
          ref={ref}
          {...rest}
        />
        {className === "area-code" &&
          <div className={styles.phoneMask}>
            <span>(</span><span>)</span> XXX-XXXX
          </div>
        }
      </div>
      {error.length > 0 &&
        <FormError text={error[0]} />
      }
    </div>
  )
)

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  pattern: PropTypes.string,
  minLength: PropTypes.number,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.array
}

Input.defaultProps = {
  type: "text",
  value: "",
  className: null,
  disabled: false,
  pattern: null,
  minLength: undefined,
  autoComplete: undefined,
  required: false,
  error: []
}

export default Input
