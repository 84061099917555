import React from "react"

import Icon from "components/Icon"
import "./Header.scss"

const SettingsHeader = ({ title }) => {

  const toggleMenu = () => {
    const sidebar = document.getElementsByClassName("settings-sidebar")[0]
    sidebar.classList.toggle("open")
  }
  
  return (
    <div className="settings-header">
      <h3>{title}</h3>
      <div className="mobile-toggle" onClick={toggleMenu}>
        <Icon name="menu" width="32" height="32" />
      </div>
    </div>
  )
}

export default SettingsHeader
