import React from "react"

import { ReactComponent as Xmark } from "./xmark.svg"
import "./Removed.scss"

const CustomerRemoved = () =>
  <div className="customer-removed">
    <Xmark />
  </div>

export default CustomerRemoved
