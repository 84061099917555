import React from "react"
import PropTypes from "prop-types"

import "./FormError.scss"

const FormError = ({ text }) => ( 
  <div className="form-error">{text}</div>
)

FormError.propTypes = {
  text: PropTypes.string.isRequired
}

export default FormError
