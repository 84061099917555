import React from "react"

import "./Content.scss"

const SettingsContent = ({ children }) => (

  <div className="settings-content">
    <div className="settings-scroll">
      {children}
    </div>
  </div>
)

export default SettingsContent
