import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { resendConfirmation } from "api/auth"

import "./ResendConfirmation.scss"

const ResendConfirmation = ({ error, email }) => {

  const dispatch = useDispatch()

  const [success, setSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    const options = {
      data: {
        email
      }
    }
    
    dispatch(resendConfirmation(options)).then(() => {
      setSuccess(true)
    }).catch(() => {})
  }

  return (
    <>
      <p>{error}</p>
      {success
        ? <p className="resend-success">Confirmation Email Resent!</p>
        : <p>
            <button 
              className="resend-confirmation"
              onClick={handleSubmit}
            >
              Resend Confirmation Email
            </button>
          </p>
      }
    </>
  )
}

export default ResendConfirmation
