import React from "react"

import Icon from "components/Icon"
import "./Header.scss"

const ReportsHeader = () => {

  const toggleMenu = () => {
    const sidebar = document.getElementsByClassName("reports-sidebar")[0]
    sidebar.classList.toggle("open")
  }
  
  return (
    <div className="reports-header">
      <h3>Analysis</h3>
      <div className="mobile-toggle" onClick={toggleMenu}>
        <Icon name="menu" width="32" height="32" />
      </div>
    </div>
  )
}

export default ReportsHeader
