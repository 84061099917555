import React from "react"
import { Link } from "react-router-dom"

import Logo from "components/Logo"
import Icon from "components/Icon"
import AccountLink from "routes/settings/account/Link"
import UsersLink from "routes/settings/users/Link"
import WaitlistLink from "routes/settings/waitlists/Link"
import "./Sidebar.scss"

const SettingsSidebar = ({ pathname }) => {

  const toggleMenu = () => {
    const sidebar = document.getElementsByClassName("settings-sidebar")[0]
    sidebar.classList.toggle("open")
  }

  return (
    <nav className="settings-sidebar">

      <div className="menu">
        <div className="menu-header">
          <Logo />
          <div className="mobile-close" onClick={toggleMenu}>
            <Icon name="close" width="24" height="24" />
          </div>
        </div>
        <ul onClick={toggleMenu}>
          <li className={pathname.match(/account/) && "active"}><AccountLink /></li>
          <li className={pathname.match(/users/) && "active"}><UsersLink /></li>
          <li className={pathname.match(/waitlists/) && "active"}><WaitlistLink /></li>
        </ul>
      </div>
      
      <div className="menu-button">
        <Link className="return" to="/lobby">Return to operator mode</Link>
      </div>
      
    </nav>
  )
}


export default SettingsSidebar
