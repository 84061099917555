import "react-app-polyfill/ie9"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { Provider } from "react-redux"
import { ToastProvider } from "react-toast-notifications"
import CustomToast from "components/CustomToast"
import store from "store"

import App from "./App"
import "./index.scss"
import * as serviceWorker from "./serviceWorker"

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_BUGSNAG_ENV
})

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <ToastProvider 
          autoDismiss={true} 
          autoDismissTimeout={4000}
          components={{ Toast: CustomToast }}
          placement="bottom-center"
        >
          <App />
        </ToastProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
