import React from "react"
import { Link } from "react-router-dom"
import { capitalize } from "utils/format"

import "./List.scss"

const UserList = ({ users }) => {

  const getUserStatus = user => {
    if(user.pending){
      return <span className="pending">Invite pending</span>
    }

    return capitalize(user.role)
  }
  
  return (
    <div className="users-list">
      {users.map(user => {

        const { id, display_name, role, current_lobby_name } = user
        const status = getUserStatus(user)

        return (
          <Link 
            key={id} 
            to={`/settings/users/${id}/edit`}
            className="user"
          >
            <div className="user-info">
              <div className="user-name">{display_name}</div>  
              <div className="user-status">
                {status}
              </div>
              {role === "operator" && 
                <div className="user-lobby">{current_lobby_name}</div>
              }
            </div>
            <div className="user-actions">
              <span>Edit</span>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default UserList
