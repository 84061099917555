import apiCall from "utils/api"
import { updateAccountUser, removeAccountUser } from "reducers/account"

export const getUser = userId => async dispatch => {
  return apiCall("get", `users/${userId}`).then(response => {
    dispatch(updateAccountUser(response.data))
    return response
  }).catch(error => {
    console.error(error.message)
    throw error
  })
}
  
export const updateUser = (userId, options) => async dispatch => {
  return apiCall("patch", `users/${userId}`, options).then(response => {
    dispatch(updateAccountUser(response.data))
    return response
  }).catch(error => {
    console.error(error.message)
    throw error
  })
}

export const deleteUser = userId => async dispatch => {
  return apiCall("delete", `users/${userId}`).then(response => {
    dispatch(removeAccountUser(userId))
    return response
  }).catch(error => {
    console.error(error.message)
    throw error
  })
}

export const switchWaitlist = options => async () => {
  return apiCall("post", `users/waitlist`, options).then(response => {
    return response
  }).catch(error => {
    console.error(error.message)
    throw error
  })
}