import React from "react"
import { Link } from "react-router-dom"

import Icon from "components/Icon"
import "./NewButton.scss"

const NewButton = ({ to, text, disabled }) => {

  const url = disabled ? "#" : to
  
  return (
    <Link to={url}>
      <div className={`new-button ${disabled ? "disabled" : ""}`}>
        <div className="plus-button">
          <Icon name="plus" height="24" width="24" />
        </div>
        <span>{text}</span>
      </div>
    </Link>
  )
}

export default NewButton
