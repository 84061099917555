import React from "react"
import { useSelector } from "react-redux"
import { formatPhoneNumber } from "utils/format"

import Logo from "components/Logo"
import InviteNextButton from "components/InviteNextButton"
import LobbySwitcher from "components/lobbies/Switcher"
import TrialStatus from "components/TrialStatus"
import SupportButton from "components/SupportButton"
import SignOutButton from "components/SignOutButton"
import ReportsLink from "components/reports/Link"
import SettingsLink from "components/settings/Link"
import "./Sidebar.scss"

const Sidebar = () => {

  const { currentUser } = useSelector(
    state => state.auth
  )

  const { account, role } = currentUser
  const { status, multiple_lobbies } = account

  const { current, loading } = useSelector(
    state => state.lobbies
  )

  const lobbyId = current.id
  const lobbyName = current.name
  
  const { display_name, phone_number } = current

  const formattedPhone = formatPhoneNumber(phone_number)

  const active = status === "active" || status === "trialing"

  const showSwitcher = role === "admin" && multiple_lobbies

  return (
    <div className="sidebar">
      <div className="sidebar-top">
        <Logo className="sidebar-logo" />
        <>
          {!loading &&
            <>
            {active && <InviteNextButton />}
              <div className="location">
                {showSwitcher 
                  ? <LobbySwitcher currentLobbyId={lobbyId} currentLobbyName={lobbyName} />
                  : <div className="location-name">
                      {display_name}  
                    </div>
                }
                {active && <p className="location-number">{`Waitlist number: ${formattedPhone}`}</p>}
              </div>
            </>
          }
        </>
      </div>
      
      <ul className="sidebar-menu">
        {account.status === "trialing" && 
          <li>
            <TrialStatus role={role} days={account.trial_days_remaining} />
          </li>
        }
        <li><SupportButton menu={true} /></li>
        <li><ReportsLink /></li>
        {role === "admin" && <li><SettingsLink /></li>}
        <li><SignOutButton /></li>
      </ul>
    </div>
  )
}

export default Sidebar
