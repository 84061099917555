import apiCall from "utils/api"
import { setAccount } from "reducers/auth"

export const updateAccount = params => async dispatch => {
  const options = { data: params }
  return apiCall("patch", `account`, options)
    .then(response => {
      const account = response
      dispatch(setAccount(account))
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}


export const manageBilling = () => async () => {
  return apiCall("post", `subscriptions/manage`)
    .then(response => {
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}
