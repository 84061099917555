import axios from "axios"
import { apiHeaders, persistAuthHeaders, removeAuthHeaders } from "utils/auth"
import store from "store"
import { signOut } from "api/auth"

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.post["Content-Type"] = "application/json"
axios.defaults.headers.patch["Content-Type"] = "application/json"
axios.defaults.headers.put["Content-Type"] = "application/json"

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        if(store.getState().auth.authenticated) {
          store.dispatch(signOut()).then(() => {})
          .catch(() => {
            console.error("User not logged in")
          })
        } else {
          removeAuthHeaders()
        }
      }
      if(error.response.status === 500) {
        // something went wrong
        console.error(error)
      }
    }
    else if (error.message === "Network Error") {
      // something went wrong
      console.error(error)
    }
    return Promise.reject(error)
  }
)

const apiCall = (method, path, options={}, responseType="json") => {
  return axios.request({
    method: method,
    url: path,
    headers: apiHeaders(),
    params: options.params,
    data: JSON.stringify(options.data),
    responseType  
  })
  .then(response => {
    persistAuthHeaders(response.headers)
    return response
  })
  .catch(error => {
    if(error.response){
      persistAuthHeaders(error.response.headers)
    }
    throw error
  })
}

export default apiCall