import React from "react"

import StripeLink from "components/account/StripeLink"
import SupportButton from "components/SupportButton"
import "./Manage.scss"

const AccountManage = () =>  {

  return (
    <div className="account-manage">
      <p>Your subscription information is stored securely with Stripe. Click the link below to update your billing information or see payment history.</p>
      <StripeLink />
      <br />
      <p>If you’d like to change or cancel your subscription, please:</p>
      <SupportButton
        label="Contact Support ->"
      />
    </div>
  )
}

export default AccountManage