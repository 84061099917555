import React from "react"
import { Bar } from "react-chartjs-2"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"

const options = {
  scales: {
    x: {
      offset: true,
      ticks: {
        color: "#c1cddb",
      }
    },
    y: {
      min: 0,
      ticks: {
        color: "#c1cddb",
        stepSize: 1
      }
    },
  },
  plugins: {   
    legend: {
      display: false
    },
  }
}

const HourlyChart = ({ raw }) => {

  dayjs.extend(customParseFormat)

  const hours = raw.map(datum => dayjs(datum.hour.toString(), "H").format("ha"))
  const visits = raw.map(datum => datum.visits)

  const data = {
    labels: hours,
    datasets: [
      {
        label: "Visits",
        data: visits,
        fill: false,
        backgroundColor: "rgb(0, 191, 243)",
        borderColor: "rgba(0, 191, 243, 1)",
      },
    ],
  }

  const chartHeight = window.innerWidth > 991 ? 60 : 180

  return (
    <>
      <div className="header">
      </div>
      <Bar 
        data={data} 
        options={options} 
        height={chartHeight}  
      />
    </>
  )
}

export default HourlyChart