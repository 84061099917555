import React, { useState, useEffect, useRef } from "react"
import { useDispatch } from "react-redux"
import { getLobbies } from "api/lobbies"
import { switchWaitlist } from "api/users"

import Icon from "components/Icon"
import "./Switcher.scss"

const LobbySwitcher = ({ currentLobbyId, currentLobbyName }) => {
  
  const dispatch = useDispatch()
  
  const [lobbies, setLobbies] = useState([])
  const [open, setOpen] = useState(false)

  const menu = useRef(null)

  useEffect(() => {
    dispatch(getLobbies()).then(response => {
      setLobbies(response.data)
    }).catch(error => console.error(error.message))

    const handleClickOutside = event => {
      if (menu.current && !menu.current.contains(event.target)) {
        if(open) setOpen(false)
      }
    }

    window.addEventListener("mousedown", handleClickOutside)
    
    return () => {
      window.removeEventListener("mousedown", handleClickOutside)
    }
  }, [dispatch, open])

  const handleSwitch = lobbyId => {
    // if current waitlist selected, just close modal
    if(lobbyId === currentLobbyId) {
      setOpen(false)
    } else {
    
      const options = {
        data: {
          user: {
            current_lobby_id: lobbyId
          }
        }
      }

      dispatch(switchWaitlist(options)).then(() => {
        window.location.reload()
      })
    }
  }

  return (
    <div className={`lobby-switcher ${open ? "open" : ""}`} ref={menu}>

      <div className="current-lobby" onClick={()=> setOpen(!open)}>
        {currentLobbyName}
        <Icon 
          name="chevron-down" 
          width="24" 
          height="16" 
        />
      </div>
      <div className="lobby-menu">
        <ul>
          {lobbies.map(lobby => {

            const { id, name } = lobby

            return (
              <li 
                key={id} 
                className="available-lobby"
                onClick={() => handleSwitch(id)}
              >
                {name}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default LobbySwitcher