/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import apiCall from "utils/api"
import { formatPhoneNumber } from "utils/format"
import fileDownload from "js-file-download"
import { Squares } from "react-activity"
import "react-activity/dist/react-activity.css"

import pdf from "assets/pdf.png"

const SignButton = ({ lobbyId, phoneNumber }) => {

  const [loading, setLoading] = useState(false)

  const formattedPhoneNumber = formatPhoneNumber(phoneNumber, true)
  
  const downloadPDF = e => {
    e.preventDefault()
    if(loading) return
    setLoading(true)
    apiCall("get", `lobbies/${lobbyId}/sign`, {}, "arraybuffer")
    .then(response => {
      fileDownload(
        response.data,
        `awaiting-sign-${formattedPhoneNumber}.pdf`,
        "application/pdf",
      )
      setLoading(false)
    }).catch(error => {
      console.error(error.message)
      setLoading(false)
    })
  }

  return (
    <div className={`download-button ${loading ? "loading" : ""}`} onClick={downloadPDF}>
      <div className="download-image">
        <img src={pdf} alt="PDF" />
      </div>
      <span className="download-text">Download Sign</span>
      <span className="loading">{loading && <Squares />}</span>
    </div>
  )
}

export default SignButton
