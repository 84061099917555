import React from "react"
import { useSelector } from "react-redux"
import { Route, Redirect } from "react-router-dom"

const PrivateRoute = ({ component: Component, ...rest }) => {

  const { authenticated } = useSelector(
    state => state.auth
  )

  return (
    <>
      <Route {...rest} render={routeProps => (
        authenticated === true
        ? <Component {...routeProps} {...rest} />
        : <Redirect to={{
            pathname: "/sign_in",
            state: { from: routeProps.location }
          }} />
      )} />
    </>
  )
}

export default PrivateRoute
