import apiCall from "utils/api"
import { setAccount } from "reducers/account"

export const getAccount = () => async dispatch => {
  return apiCall("get", `account`)
    .then(response => {
      dispatch(setAccount(response.data))
      return response
    }).catch(error => {
      console.error(error.message)
      throw error
    })
}