import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { usePageView } from "utils/analytics"
import { createLobby } from "api/lobbies"

import Auth from "routes/Auth"
import CreateForm from "components/lobbies/CreateForm"

const NewLobbyRoute = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector(
    state => state.auth
  )

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const handleSubmit = params => {
    const options = {
      data: {
        lobby: params
      }
    }

    resetErrors()
    setLoading(true)
    
    dispatch(createLobby(options)).then(() => {
      setLoading(false)
    }).catch(error => {
      setErrors(error.response.data)
      setLoading(false)
    })
  }

  usePageView("Sign Up")

  const resetErrors = () => setErrors({})

  if(currentUser.current_lobby_id) {
    return <Redirect to="/lobby" />
  }

  return (

    <Auth className="auth-top">
      <p>Create your first waitlist.</p>
      <div className="auth-form">
        <CreateForm
          loading={loading}
          errors={errors}
          handleSubmit={handleSubmit}
          showWarning={false}
        />
      </div>
    </Auth>
  )
}

export default NewLobbyRoute
