import { useEffect } from "react"

export function usePageView(name) {
  useEffect(() => {
    window.analytics && window.analytics.page(name)
  }, [name]) //only track if page name changes
}

export function identifyUser(userId) {
  window.analytics && window.analytics.identify(userId)
}

export function resetIdentity() {
  window.analytics && window.analytics.reset()
}