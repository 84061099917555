import React, { useState } from "react"
import { formatPhoneNumber } from "utils/format"
import { timezones } from "./timezones"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Textarea from "components/Textarea"
import Button from "components/Button"
import "./Form.scss"

const LobbyForm = ({ 
  lobby,
  errors, 
  loading, 
  handleSubmit
}) => {

  const [name, setName] = useState(lobby.name)
  const [timezone, setTimezone] = useState(lobby.timezone)
  const [response, setResponse] = useState(lobby.sms_response)
  const [visitLink, setVisitLink] = useState(lobby.visit_link || false)
  const [visitLinkText, setVisitLinkText] = useState(lobby.visit_link_text)
  const [invitation, setInvitation] = useState(lobby.sms_invitation)

  const prepareData = e => {
    e.preventDefault()
    const params = {
      name,
      timezone,
      sms_response: response,
      sms_invitation: invitation,
      visit_link: visitLink,
      visit_link_text: visitLinkText
    }

    handleSubmit(params)
  }

  const setDefaultResponse = () => {
    setResponse(lobby.default_response)
  }

  const setDefaultInvitation = () => {
    setInvitation(lobby.default_invitation)
  }

  const setDefaultVisitLinkText = () => {
    setVisitLinkText(lobby.default_visit_link_text)
  }

  const getErrors = name => {
    return errors[name] || []
  }

  return (
    <form className="lobby-form" onSubmit={prepareData}>

      <fieldset>

        <Input
          className="disabled"
          type="phone"
          name="name"
          label="Waitlist number"
          value={formatPhoneNumber(lobby.phone_number)}
          disabled={true}
          error={getErrors("name")}
          onChange={e => setName(e.target.value)}
        />

        <Input
          type="text"
          name="name"
          label="Waitlist name"
          value={name}
          disabled={loading}
          error={getErrors("name")}
          onChange={e => setName(e.target.value)}
        />

        <div className="field timezone">
          <label className="label" htmlFor="timezone">Time Zone</label>
          <select
            id="timezone"
            name="timezone"
            value={timezone}
            onChange={e => setTimezone(e.target.value)}
          >
            {timezones.map(timezone => <option key={timezone} value={timezone}>{timezone}</option>)}
          </select>
        </div>

        <div className="label">
          <label htmlFor="response">Check-in auto-response</label>
          <button type="button" onClick={setDefaultResponse}>Reset to default</button>
        </div>
        
        <Textarea
          name="response"
          value={response}
          disabled={loading}
          rows={3}
          grow={true}
          error={getErrors("sms_response")}
          onChange={e => setResponse(e.target.value)}
        />

        <Checkbox
          wrapperClass="field"
          id="visitor_link"
          name="visitor_link"
          checked={visitLink}
          onChange={() => setVisitLink(!visitLink)}
          label="Send visitor a link to a page showing their place in line"
        />

        <div className={`link-message ${!visitLink && "hide"}`}>

          <div className="label">
            <label htmlFor="response">Place in line message</label>
            <button type="button" onClick={setDefaultVisitLinkText}>Reset to default</button>
          </div>

          <Textarea
            name="visit_link_text"
            value={visitLinkText}
            disabled={loading}
            rows={1}
            grow={true}
            error={getErrors("visit_link_text")}
            helperText={"This will be added to the check-in response. {link} will be replaced with the link to their place in line."}
            onChange={e => setVisitLinkText(e.target.value)}
          />
        </div>

        <div className="label">
          <label htmlFor="response">Invitation message</label>
          <button type="button" onClick={setDefaultInvitation}>Reset to default</button>
        </div>
        
        <Textarea
          name="invitation"
          value={invitation}
          disabled={loading}
          rows={1}
          grow={true}
          error={getErrors("sms_invitation")}
          onChange={e => setInvitation(e.target.value)}
        />
      </fieldset>

      <Button
        className="submit"
        label="Update"
        type="submit"
        loading={loading}
      />

    </form>
  )
}

export default LobbyForm
