import React from "react"

import "./StatusTag.scss"

const StatusTag = ({ status }) => {

  return (
    <div className={`status-tag ${status}`}>
      {status}
    </div>
  )
}

export default StatusTag
