import React, { useEffect, useState } from "react"
import { useDispatch, useSelector, shallowEqual } from "react-redux"
import { usePageView } from "utils/analytics"
import { useParams } from "react-router-dom"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import debounce from "lodash/debounce"
import { getVisit, cancelVisit } from "api/visits"
import { setVisit } from "reducers/customer"
import { setVisitLoading, setVisitLoaded } from "reducers/ui"
import { formatPhoneNumber } from "utils/format"
import ActionCableProvider from "components/ActionCableProvider"
import { ActionCableConsumer } from "@thrash-industries/react-actioncable-provider"

import Footer from "components/customer/Footer"
import PlaceInLine from "components/customer/PlaceInLine"
import RemovedImage from "components/customer/Removed"
// import EstimatedWaitTime from "components/customer/EstimatedWaitTime"
import "./Visit.scss"

const Visited = ({ visit }) =>
  <>
    <div className="wrapper">
      <PlaceInLine state={visit.state} placeInLine={visit.place_in_line} />
    </div>
    <div className="notice description">
      <p>{visit.invitation}</p>
    </div>
  </>

const Waiting = ({ visit, handleReceive, handleCancel }) =>
  <>
    <ActionCableConsumer
      channel={{ channel: "Customer::VisitChannel", visit: visit.id }}
      onReceived={handleReceive}
    />
    <div className="notice description">
      <p>{visit.intro}</p>
    </div>
    <div className="wrapper">
      <PlaceInLine placeInLine={visit.place_in_line} />
    </div>
    {/* 
      <div className="description">
        <EstimatedWaitTime visit={visit} />
      </div> 
    */}
    {/* for debug purposes */}
    {/* 
    {/* <div className="debug" style={{ fontFamily: "monospace" }}>
      avg: {Number.parseFloat(visit.average_wait_time  / 60).toFixed(2)} min
    </div>
    */}
    <div className="visit-action">
      <button className="cancel" onClick={handleCancel}>Cancel My Spot In Line</button>
    </div>
  </>

const Removed = ({ visit }) => 
  <>
    <div className="wrapper">
      <RemovedImage />
    </div>
    <div className="notice description">
      <p>
        You’ve been removed from the waiting list. If you'd like to 
        join the list again, text us your name to {` `}
        <a href={`sms:${visit.phone_number}`}>
          {formatPhoneNumber(visit.phone_number)}
        </a>!
      </p>
    </div>
  </>

const Canceled = ({ visit }) => 
  <>
    <div className="wrapper">
      <RemovedImage />
    </div>
    <div className="notice description">
      <p>
        You successfully removed yourself from the wait list. If you'd like to 
        join the list again, text us your name to {` `}
        <a href={`sms:${visit.phone_number}`}>
          {formatPhoneNumber(visit.phone_number)}
        </a>!
      </p>
    </div>
  </>

const VisitRoute = () => {

  dayjs.extend(localizedFormat)

  const [timestamp, setTimestamp] = useState(Date.now())
  const [visitNotFound, setVisitNotFound] = useState(false)

  const { visitLoading } = useSelector(state => state.ui)
  const visit = useSelector(state => state.customer.visit, shallowEqual)
  const dispatch = useDispatch()
  const { uuid } = useParams()

  const updateTimestamp = () => {
    setTimestamp(Date.now)
  }

  useEffect(() => {
    dispatch(setVisitLoading())

    const refreshVisit = debounce(() => {
      dispatch(getVisit(uuid))
      updateTimestamp()
    }, 500)
    
    dispatch(getVisit(uuid)).then(() => {
      dispatch(setVisitLoaded())
      updateTimestamp()
      window.addEventListener("focus", refreshVisit)
    }).catch(() => {
      setVisitNotFound(true)
      dispatch(setVisitLoaded())
    })
    
    return () => {
      window.removeEventListener("focus", refreshVisit)
    }
  }, [uuid, dispatch])

  usePageView("Visit - Waiting")

  const handleCancel = () => {
    if(window.confirm("Are you sure you want to cancel your spot in line?")){
      dispatch(cancelVisit(uuid))
        .catch(error => {
          if (error.response.data.error === "COULD_NOT_CANCEL") {
            // TODO: use toast notification or something
            alert("Could not cancel")
          }
        })
    }
  }

  const handleReceive = visit => {
    dispatch(setVisit(visit))
    updateTimestamp()
    console.log("Dispatch - updated visit")
  }

  if (visitLoading) return null

  if (visitNotFound) return (
    <div className="visit">
      <div className="wrapper">
        Hmm, we couldn't find your visit. Please check your link and try again.
      </div>
      <Footer />
    </div>
  )

  const { account } = visit

  const VisitState = ({ visit }) => {
    
    switch(visit.state) {
      case "canceled":
        return <Canceled visit={visit} />
      case "removed":
        return <Removed visit={visit} />
      case "visited":
        return <Visited visit={visit} />
      case "waiting":
      case "pending":
        return <Waiting visit={visit} handleReceive={handleReceive} handleCancel={handleCancel} />
      default:
        return <p>Something went wrong</p>
    }
  }
  
  return (
    <div className="visit">
      <ActionCableProvider>
        <div className="content">
          <h4 className="account">{account}</h4>
          <VisitState
            visit={visit}
            handleReceive={handleReceive} 
            handleCancel={handleCancel}
          />
          {!visit && <>
            <h1>404</h1>
            <h4>Not Found</h4>
            </>
          }
          <p className="timestamp">
            {`Updated ${dayjs(timestamp).format("LLL")}`}
          </p>
        </div>
      </ActionCableProvider>
      <Footer />
    </div>
  )
}

export default VisitRoute
