import React, { useState, memo } from "react"
import { useDispatch } from "react-redux"
import { admitVisitor, removeVisitor } from "api/visits"

import Icon from "components/Icon"
import Button from "components/Button"
import "./Actions.scss"

const VisitCardActions = ({ visit }) => {

  const dispatch = useDispatch()

  const { id, state, last_message_error_code } = visit

  const lastMessageError = last_message_error_code !== null && typeof last_message_error_code != "undefined"

  const [inviteLoading, setInviteLoading] = useState(false)
  const [removeLoading, setRemoveLoading] = useState(false)

  const handleAdmitVisitor = e => {
    e.preventDefault()
    if(inviteLoading) return
    setInviteLoading(true)
    dispatch(admitVisitor(id))
      .then(() => setInviteLoading(false))
      .catch(() => setInviteLoading(false))
  }

  const handleRemoveVisitor = e => {
    e.preventDefault()
    if(removeLoading) return
    setRemoveLoading(true)
    if(state === "visited" || window.confirm("Are you sure you want to remove this visitor")){
      dispatch(removeVisitor(id))
        .then(() => {})
        .catch(() => setRemoveLoading(false))
    } else {
      setRemoveLoading(false)
    }
  }

  const RemoveButtonText = () => (
    <>
      <Icon name="remove" width="22" height="22" />
      <span>Remove</span>
    </>
  )

  // console.log("state", state)
  // console.log("invite delivered", invite_delivered)
  // console.log("error code", invite_error_code)

  const InviteButtonText = () => {
    if(lastMessageError) {
      return (
        <>
          <Icon name="failed" width="18" height="18" />
          <span className="failed">Error {last_message_error_code}</span>
        </>
      )
    }
    else if(state === "waiting") {
      return (
        <>
          <Icon name="invite" width="18" height="18" />
          <span>{inviteLoading ? "Sending..." : "Send Invite"}</span>
        </>
      )
    } else if(state === "pending") {
      return (
        <>
          <Icon name="invite" width="18" height="18" />
          <span>Sending...</span>
        </>
      )
    } else if(state === "visited") {

      return (
        <>
          <Icon name="invited" width="18" height="18" />
          <span className="sent">Sent Invite</span>
        </>
      )
    }
  }

  return (
    <div className="visit-card-actions">
      <form onSubmit={handleRemoveVisitor} className="remove">
        <Button
          title="Remove"
          label={<RemoveButtonText />}
          type="submit"
          loading={removeLoading || inviteLoading}
        />
      </form>
      <form onSubmit={handleAdmitVisitor} className="send-invite">
        <Button
          className="send_invite"
          title="Send Invite"
          label={<InviteButtonText />}
          type="submit"
          loading={inviteLoading || state !== "waiting" || lastMessageError}
        />
      </form>
    </div>
  )
}

export default memo(VisitCardActions)
