import React from "react"
import dayjs from "dayjs"
import "./Message.scss"

 const Message = ({ message }) => {

  const messageDelivered = message.delivered
  const messageError = message.error_code
  
  return (
    <div className={`message ${message.direction}`}>
      <div className="message-body">
        <p>{message.body}</p>
      </div>
      <div className="message-meta">
        <span className="message-timing">{dayjs(message.time).format("h:mm a")}</span>
        {messageDelivered &&
          <span className="message-delivered">Delivered</span>
        }
        {messageError &&
          <span className="message-error">Error {messageError}</span>
        }
      </div>
    </div>
  )
}

export default Message