import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { persistAuthHeaders } from "utils/auth"
import queryString from "query-string"
import { updatePassword } from "api/auth"
import { usePageView } from "utils/analytics"

import Auth from "routes/Auth"
import Input from "components/Input"
import Button from "components/Button"
import Icon from "components/Icon"
import "./Auth.scss"

const ResetPasswordRoute = props => {

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const search = props.location.search
    const qs = queryString.parse(search)
    // sends an object of auth headers
    persistAuthHeaders(qs)
  }, [props.location.search])

  usePageView("Reset Password")

  const handleSubmit = e => {
    e.preventDefault()

    const options = {
      data: {
        password,
        password_confirmation: confirm
      }
    }

    setLoading(true)
    dispatch(updatePassword(options)).then(response => {
      setSuccess(true)
      setLoading(false)
    }).catch(error => {
      setLoading(false)
      console.error(error.message)
    })
  }

  return (
    <Auth>
      {!success
        ? 
          <>
            <h3>Change password</h3>
            <form onSubmit={handleSubmit} className="auth-form reset-password-form">

              <fieldset>
                <Input
                  type="password"
                  name="password"
                  label="Create a new password"
                  value={password}
                  disabled={loading}
                  onChange={e => setPassword(e.target.value)}
                />

                <Input
                  type="password"
                  name="confirm"
                  label="Confirm your new password"
                  value={confirm}
                  disabled={loading}
                  onChange={e => setConfirm(e.target.value)}
                />
              </fieldset>

              <Button
                label="Change password"
                type="submit"
                loading={loading}
              />

            </form>
            <Link to="/sign_in">Cancel</Link>
          </>
        : <div className="success-message">
            <Icon name="success" width="88" height="88" />
            <p>Your password has been <br />successfully updated!</p>
            <Link 
              className="button-link"
              to="/lobby"
            >
              Back to waitlist
            </Link>
          </div>
      }
    </Auth>
  )
}

export default ResetPasswordRoute
