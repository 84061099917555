import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { createLobby } from "api/lobbies"
import { usePageView } from "utils/analytics"

import Header from "routes/settings/Header"
import Content from "routes/settings/Content"
import SupportButton from "components/SupportButton"
import CreateForm from "components/lobbies/CreateForm"
import "./New.scss"

const NewWaitlistRoute = () => {

  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { status, payment_method } = useSelector(
    state => state.account
  )

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const handleSubmit = params => {
    const options = {
      data: {
        lobby: params
      }
    }

    resetErrors()
    setLoading(true)
    
    dispatch(createLobby(options)).then(() => {
      setLoading(false)
      addToast("Waitlist created!", { appearance: "success" })
      history.push("/settings/waitlists")
    }).catch(error => {
      setErrors(error.response.data)
      setLoading(false)
    })
  }

  const resetErrors = () => setErrors({})

  // ugly but works
  const isActive = payment_method && (status === "active" || status === "trialing")

  usePageView("New Waitlist")
  
  return (
    <div className="new-waitlist">
      <Header title="New waitlist" />
      <Content>
        {isActive
         ? <CreateForm
              loading={loading}
              errors={errors}
              handleSubmit={handleSubmit}
              showWarning={true}
            />
          : <p>You cannot add new waitlists at this time, please:
              <SupportButton
                label="Contact Support ->"
              />
            </p>
        }
      </Content>
    </div>
    
  )
}

export default NewWaitlistRoute
