import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { sendInvitation } from "api/invitations"

import Header from "routes/settings/Header"
import Content from "routes/settings/Content"
import UserForm from "components/users/Form"
import "./Invite.scss"

const UserInviteRoute = () => {

  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const handleSubmit = params => {
    const options = {
      data: {
        invite: params
      }
    }
    
    resetErrors()
    setLoading(true)
    
    dispatch(sendInvitation(options)).then(() => {
      setLoading(false)
      addToast("User invited!", { appearance: "success" })
      history.push("/settings/users")
    }).catch(error => {
      setErrors(error.response.data)
      setLoading(false)
    })
  }

  const resetErrors = () => setErrors({})

  return (
    <div className="invite-user">
      <Header title="Invite user" />
      <Content>
        <UserForm 
          user={{}}
          errors={errors}
          loading={loading}
          submitText="Send Invite"
          roleEdit={true}
          handleSubmit={handleSubmit}
        />  
      </Content>
    </div>
  )
}

export default UserInviteRoute

