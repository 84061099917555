import { createSlice } from "@reduxjs/toolkit";

const customerSlice = createSlice({
  name: "visit",
  initialState: {
    visit: null,
    loading: true
  },
  reducers: {
    setVisit(state, action) {
      return { ...state, visit: action.payload }
    }
  }
})

export const {
  setVisit
} = customerSlice.actions

export default customerSlice.reducer
