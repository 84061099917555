import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { resetPassword } from "api/auth"
import { usePageView } from "utils/analytics"

import Auth from "routes/Auth"
import Input from "components/Input"
import Button from "components/Button"
import "./Auth.scss"

const ForgotPasswordRoute = () => {

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()

    const options = {
      data: {
        email,
        redirect_url: process.env.REACT_APP_PASSWORD_RESET_URL
      }
    }

    setLoading(true)

    dispatch(resetPassword(options)).then(() => {
      setLoading(false)
      setSuccess(true)
    }).catch(() => {
      setLoading(false)
      setSuccess(true)
    })
  }

  usePageView("Forgot Password")

  return (

    <Auth>
      {!success
        ? <>
            <h3>Forgot password?</h3>
            <form onSubmit={handleSubmit} className="auth-form sign-in-form">

              <p className="text-center">Enter the email you use for Awaiting and we’ll send you an email with a password reset link.</p>

              <fieldset>
                <Input
                  type="email"
                  name="email"
                  label="Email address"
                  value={email}
                  disabled={loading}
                  required
                  onChange={e => setEmail(e.target.value)}
                />
              </fieldset>

              <Button
                label="Email password reset link"
                type="submit"
                loading={loading}
              />

            </form>
          </>
        : <p className="text-center">An email with a reset password link has been sent to your address. 
             If you don’t see the email, check your spam folder!</p>
      }
      <Link to="/sign_in">Sign in</Link>
    </Auth>
  )
}

export default ForgotPasswordRoute
