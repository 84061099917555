export const capitalize = string => {
  return string.replace(/^\w/, c => c.toUpperCase())
}

export const formatCurrency = (number = 0, cents = true) => {
  const decimals = cents ? 2 : 0
  return number.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimals,
  })
}

export const formatPhoneNumber = (string, dashes = false) => {
  const digits = parsePhoneNumber(string)
  if(!digits) return null
  const areaCode = dashes ? `${digits[1]}-` : `(${digits[1]}) `
  return `${areaCode}${digits[2]}-${digits[3]}`
}

export const obfuscatePhoneNumber = string => {
  const digits = parsePhoneNumber(string)
  return !digits ? null : `XXX-XXX-${digits[3]}`
}

export const getOrdinalNumber = number => {
    const endings = ['th', 'st', 'nd', 'rd'],
          v = number % 100;
    return number + (endings[(v-20)%10] || endings[v] || endings[0]);
}

const parsePhoneNumber = string => {
  const s2 = ("" + string).replace(/\+1|\D/g, "")
  const main = s2.slice(0, 10)
  return main.match(/^(\d{3})(\d{3})(\d{4})$/)
}
