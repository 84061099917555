import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getAccount } from "api/account"
import { formatPhoneNumber } from "utils/format"

import Header from "routes/settings/Header"
import Content from "routes/settings/Content"
import StripeLink from "components/account/StripeLink"
import SupportButton from "components/SupportButton"
import VisitsCount from "components/visits/Count"
import NewButton from "components/settings/NewButton"
import "./Index.scss"

const WaitlistsRoute = () => {

  const dispatch = useDispatch()

  const { status, lobbies, payment_method } = useSelector(
    state => state.account
  )

  useEffect(() => {
    dispatch(getAccount()).then(() => {
    }).catch(error => console.error(error.message))
  }, [dispatch])

  // ugly but works
  const isActive = payment_method && (status === "active" || status === "trialing")

  return (
    <div className="manage-waitlists">

      <Header title="Manage waitlists" />

      <Content>
        <ul className="lobby-list">
          {lobbies.map(lobby => {

            const { id, active_visits_count, name, phone_number } = lobby

            return (
              <li key={id}>
                <Link className="lobby" to={`waitlists/${id}/edit`}>
                  <div className="lobby-left">
                    <VisitsCount count={active_visits_count} />
                    <div className="lobby-info">
                      <div className="lobby-name">{name}</div>
                      <div className="lobby-phone">{formatPhoneNumber(phone_number)}</div>
                    </div>
                  </div>
                  <div className="lobby-actions">
                    <span>Edit</span>
                  </div>
                </Link>
              </li>
            )
          })}
          <NewButton 
            to="waitlists/new" 
            text="Add new waitlist" 
            disabled={!isActive} 
          />
        </ul>

        {status === "canceled" &&
          <div className="trial-message">
            <p>Contact support to re-activate your account</p>
          </div>
        }

        {!payment_method &&
          <div className="trial-message">
            <p>If you’d like additional waitlists please add your credit card.</p>
            <StripeLink />
          </div>
        }

        <p>If you’d like to remove a waitlist, please:
          <SupportButton
            label="Contact Support ->"
          />
        </p>
      </Content>
    </div>
  )
}

export default WaitlistsRoute