import React, { useState, useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import queryString from "query-string"
import dayjs from "dayjs"
import debounce from "lodash/debounce"
import { getLobbyVisits } from "api/reports"
import { formatPhoneNumber } from "utils/format"
import duration from "dayjs/plugin/duration"
import relativeTime from "dayjs/plugin/relativeTime"
import { Squares } from "react-activity"

import Pitch from "routes/reports/Pitch"
import Sidebar from "routes/reports/Sidebar"
import Header from "routes/reports/Header"
import LobbySwitcher from "components/lobbies/Switcher"
import RangePicker from "components/reports/RangePicker"
import HourlyChart from "components/reports/HourlyChart"
import DailyChart from "components/reports/DailyChart"
import Table from "components/reports/Table"
import StatusTag from "components/reports/StatusTag"
import "./Reports.scss"

const ReportsRoute = () => {

  const DATE_FORMAT = "YYYY/MM/DD"

  dayjs.extend(duration)
  dayjs.extend(relativeTime)

  const { currentUser } = useSelector(
    state => state.auth
  )
  const { account } = currentUser
  const { multiple_lobbies, reporting } = account

  const headerRef = useRef()

  const lobbyId = currentUser.current_lobby_id
  const lobbyName = currentUser.current_lobby_name

  const params = queryString.parse(window.location.search)

  let initialStartDate, initialEndDate
  
  if(params.start_date && params.end_date) {
    initialStartDate = dayjs(params.start_date).format(DATE_FORMAT)
    initialEndDate = dayjs(params.end_date).format(DATE_FORMAT)
  } else {
    initialStartDate = dayjs().startOf("month").format(DATE_FORMAT)
    initialEndDate = dayjs().endOf("month").format(DATE_FORMAT)
  }

  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(new Date(initialStartDate))
  const [endDate, setEndDate] = useState(new Date (initialEndDate))
  const [stats, setStats] = useState({
    total: 0, invited: 0, removed: 0, canceled: 0, max_wait_time: 0, min_wait_time: 0, avg_wait_time: 0
  })
  const [data, setVisits] = useState([])
  const [hourly, setHourly] = useState([])
  const [daily, setDaily] = useState([])
  const [sectionOffsets, setSectionOffsets] = useState([])
  const [activeSection, setActiveSection] = useState(0)

  const dispatch = useDispatch()

  const sections = [
    { label: "summary", class: "summary" },
    { label: "visits by day", class: "daily" },
    { label: "by time of day", class: "hourly" },
    { label: "wait times", class: "wait-times" },
    { label: "visitor list", class: "visitors" },
  ]

  useEffect(() => {
    if(reporting) {
      setLoading(true)
      dispatch(getLobbyVisits(lobbyId, dayjs(startDate).format(), dayjs(endDate).format())).then(response => {
        setStats(response.data.stats)
        setVisits(response.data.visits)
        setDaily(response.data.daily)
        setHourly(response.data.hourly)
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        console.error(error.message)
      })
    }
  }, [reporting, lobbyId, startDate, endDate, dispatch])

  useEffect(() => {

    if(reporting) {
      // get list of sections
      const sections = document.getElementsByClassName("report-section")
      // height of the static header
      const headerHeight = headerRef.current.offsetHeight

      const sectionPositions = Array.prototype.map.call(
        sections,
        e => e.offsetTop - headerHeight - 8 // tweaking
      )

      setSectionOffsets(sectionPositions)

      const handleScroll = () => {
        //Current scroll position
        const scrollPosition = window.pageYOffset

        const activeIndex = Math.max(...sectionPositions.reduce((acc, el, i) => 
          (el <= scrollPosition ? [...acc, i] : acc), [])
        )

        setActiveSection(activeIndex)
      }

      window.addEventListener("scroll", debounce(handleScroll, 20))
    }
  }, [reporting])

  const scrollToSection = section => {
    const sectionIndex = sections.indexOf(section)
    setActiveSection(sectionIndex)
    window.scroll({
      top: sectionOffsets[sectionIndex],
      behavior: "smooth"
    })
  }

  const updateQueryString = params => {
    const path = window.location.pathname
    const queryStr = queryString.parse(window.location.search)
    const newParams = { ...queryStr, ...params }
    const newURL = path + "?" + queryString.stringify(newParams)
    window.history.replaceState(null, null, newURL)
  }

  const updateStartDate = date => {
    setStartDate(date)
    const params = { start_date: dayjs(date).format(DATE_FORMAT) }
    updateQueryString(params)
  }

  const updateEndDate = date => {
    setEndDate(date)
    const params = { end_date: dayjs(date).format(DATE_FORMAT) }
    updateQueryString(params)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Phone Number",
        Cell: ({ value }) => formatPhoneNumber(value),
        accessor: "visitor.phone_number",
      },
      {
        Header: "Joined",
        Cell: ({ value }) => dayjs(value).format("ddd, MMM D h:mm A"),
        accessor: "created_at",
      },
      {
        Header: "Invited / Removed",
        Cell: ({ value }) =>
          value ? dayjs(value).format("ddd, MMM D h:mm A") : String.fromCharCode(8212),
        accessor: "state_changed_at",
      },
      {
        Header: "Time Waited",
        Cell: ({ row, value }) => (
          row.original.state === "visited" || row.original.state === "canceled" 
            ? dayjs.duration(value, "seconds").humanize() 
            : String.fromCharCode(8212)
        ),
        accessor: "time_waited",
      },
      {
        Header: "Status",
        Cell: ({ value }) => <StatusTag status={value} />,
        accessor: "state",
        className: "text-center",
        headerClassName: "text-center"
      },
    ],
    []
  )

  const { total, invited, removed, canceled, avg_wait_time, max_wait_time, min_wait_time } = stats

  const maxDisplay = max_wait_time ? dayjs.duration(max_wait_time, "seconds").humanize() : 0
  const minDisplay = min_wait_time ? dayjs.duration(min_wait_time, "seconds").humanize() : 0
  const avgDisplay = avg_wait_time ? dayjs.duration(avg_wait_time, "seconds").humanize() : 0

  let content = <Pitch />

  if(reporting) {
    content = 
    <div className="report">
      <Header />
      <div className="report-header" ref={headerRef}>
        <div className="report-waitlist">
          {multiple_lobbies 
            ? <LobbySwitcher currentLobbyId={lobbyId} currentLobbyName={lobbyName} />
            : <div className="location-name">
                {lobbyName}
              </div>
          }
        </div>
        <RangePicker 
          startDate={startDate} 
          endDate={endDate} 
          handleStartChange={updateStartDate}
          handleEndChange={updateEndDate}
        />
      </div>

      <div className="report-summary offset-top report-section">
        <div className="with-loading">
          <h4>Summary</h4>
          <span className="loading">{loading && <Squares />}</span>
        </div>
        
        <div className="report-portlet">
          <div className="stat-row">
            <div className="stat">
              <span className="stat-large">{total}</span>
              <h5>Total visits</h5>
            </div>
            <div className="stat">
              <span className="stat-large">{invited}</span>  
              <h5>Invited</h5>
            </div>
            <div className="stat">
              <span className="stat-large">{removed}</span>
              <h5>Removed</h5>
            </div>
            <div className="stat">
              <span className="stat-large">{canceled}</span>
              <h5>Canceled</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="report-chart report-section">
        <h4>Visits by day</h4>
        <div className="report-portlet">
          <DailyChart raw={daily} />
        </div>
      </div>

      <div className="report-chart report-section">
        <h4>By time of day</h4>
        <div className="report-portlet">
          <HourlyChart raw={hourly} />
        </div>
      </div>

      <div className="report-waits report-section">
        <h4>Wait times</h4>
        <div className="report-portlet">
          <div className="stat-row">
            <div className="stat">
              <span>{minDisplay}</span>
              <h5>Shortest wait</h5>
            </div>
            <div className="stat">
              <span>{avgDisplay}</span>
              <h5>Avg wait time</h5>
            </div>
            <div className="stat">
              <span>{maxDisplay}</span>
              <h5>Longest wait</h5>
            </div>
          </div>
        </div>
      </div>
      
      <div className="report-table report-section">
        <h4>Visitor list</h4>
        <Table columns={columns} data={data} />
      </div>
    </div>
  }

  return (
    <div className="reports-container">

      <Sidebar 
        sections={sections} 
        activeSectionIndex={activeSection} 
        handleMenuClick={scrollToSection}
        active={reporting} 
      />

      {content}
    </div> 
  )
}

export default ReportsRoute 