import React, { useState, useEffect } from "react"
import DatePicker from "react-datepicker"
import dayjs from "dayjs"

import "react-datepicker/dist/react-datepicker.css"
import "./RangePicker.scss"

const RangePicker = ({ 
  startDate, 
  endDate,
  handleStartChange,
  handleEndChange
}) => {

  const dateFormat = "YYYY-MM-DD"

  const [range, setRange] = useState("This Month")

  const ranges = [
    { label: "Custom range", startDate: null, endDate: null },
    { label: "Today", startDate: dayjs(), endDate: dayjs() },
    { label: "Yesterday", startDate: dayjs().subtract(1, "day"), endDate: dayjs().subtract(1, "day") },
    { label: "This Week", startDate: dayjs().startOf("week"), endDate: dayjs() },
    { label: "Last Week", startDate: dayjs().subtract(1, "week").startOf("week"), endDate: dayjs().subtract(1, "week").endOf("week") },
    { label: "Last 7 Days", startDate: dayjs().subtract(6, "day"), endDate: dayjs() },
    { label: "This Month", startDate: dayjs().startOf("month"), endDate: dayjs().endOf("month") },
    { label: "Last Month", startDate: dayjs().subtract(1, "month").startOf("month"), endDate: dayjs().subtract(1, "month").endOf("month") },
    { label: "Last 30 Days", startDate: dayjs().subtract(30, "day"), endDate: dayjs() },
    { label: "This Year", startDate: dayjs().startOf("year"), endDate: dayjs().endOf("year") },
    { label: "Last Year", startDate: dayjs().subtract(1, "year").startOf("year"), endDate: dayjs().subtract(1, "year").endOf("year") },
  ]

  useEffect(() => {
    const selectedRange = ranges.find(range => 
      range.startDate?.format(dateFormat) === dayjs(startDate).format(dateFormat) &&
      range.endDate?.format(dateFormat) === dayjs(endDate).format(dateFormat)
    )?.label || "Custom range"
    setRange(selectedRange)
  }, [ranges, startDate, endDate])

  const setDates = selected => {
    const range = ranges.find(range => range.label === selected)

    handleStartChange(new Date(range.startDate.format()))
    handleEndChange(new Date (range.endDate.format()))
  }
  
  return (

    <div className="range-picker">
      <select
        id="timezone"
        name="timezone"
        value={range}
        onChange={e => setDates(e.target.value)}
      >
        {ranges.map(range => <option key={range.label} value={range.label}>{range.label}</option>)}
      </select>
      <div className="dates">
        <div className="start-date">
          <span>From:</span>
          <DatePicker 
            selected={startDate} 
            onChange={date => handleStartChange(date)} 
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <div className="end-date">
          <span>To:</span>
          <DatePicker 
            selected={endDate} 
            onChange={date => handleEndChange(date)}   
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </div>
      </div>
    </div>
  
  )
}

export default RangePicker 