import React from "react"
import { Link } from "react-router-dom"
import CardContent from "components/visits/card/Content"
import CardActions from "components/visits/card/Actions"
import "./Card.scss"

const VisitCard = ({ visit, highlighted, darkened }) => {

  const { visitor_id } = visit

  const linkPath = highlighted ? `/lobby` : `/visitors/${visitor_id}`
  
  return (
    <div className={`visit-card ${highlighted ? "highlighted" : ""} ${darkened ? "darkened" : ""}`}>
      <Link to={linkPath}>
        <CardContent 
          visit={visit} 
          highlighted={highlighted}  
        />
      </Link>
      <CardActions visit={visit} />
    </div>
  )
}

export default VisitCard
