import React from "react"

import empty from "assets/empty.svg"
import "./Empty.scss"

const EmptyLobby = () => ( 
  <div className="empty-lobby">
    <div className="instructions">
      <h3>No one is awaiting to come in :)</h3>
      <p>When people send a text message to your waitlist number, they will appear in your waitlist.</p>
      <p>To grant them access, just select "Invite next visitor." They will receive a text message letting them know it's okay to come inside.</p>

    </div>
    <div className="empty-lobby-image">
      <img src={empty} alt="No visitors" />
    </div>
  </div>
)

export default EmptyLobby
