import React from "react"
import { Link } from "react-router-dom"

import Button  from "components/Button"

const SupportButton = ({label, menu}) => {

  const cw = e => {
    e.preventDefault()
    window.$chatwoot.toggle("open")
  } 

  return (
    <>
      {menu ?
        <div className="menu-button">
          <Button
            className="support"
            id="support-button"
            onClick={cw}
            label={label}
          />
        </div>
        :
        <Link 
          onClick={cw}
          to="#"
        >
          {label}
        </Link>
      }
    </>
  )
}

SupportButton.defaultProps = {
  label: "Help and feedback",
  menu: false
}

export default SupportButton
