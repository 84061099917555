import React, { Component } from "react"
import * as ReactDOM from "react-dom"
import PropTypes from "prop-types"
import classNames from "classnames/bind"
import styles from "./Textarea.module.scss"

import FormError from "components/FormError"

const cx = classNames.bind(styles)

class TextArea extends Component {

  constructor(props) {
    super(props)

    this.state = {
      rows: props.rows,
      minRows: props.rows,
      maxRows: 10,
    }
  }

  componentDidMount() {
    if(this.props.grow) this.calculateRows()
  }

  componentDidUpdate(prevProps){
    if(this.props.value !== prevProps.value){
      if(this.props.grow) this.calculateRows()
    }
  }

  calculateRows = () => {
    const ref = this.ref
    const refNode = ReactDOM.findDOMNode(this.ref)
    const textareaLineHeight = 30
    const { minRows, maxRows } = this.state
    
    const previousRows = ref.rows
    refNode.rows = minRows
    
    const currentRows = ~~(ref.scrollHeight / textareaLineHeight) - 1
    
    if (currentRows === previousRows) {
      refNode.rows = currentRows
    }
    
    if (currentRows >= maxRows) {
      refNode.rows = maxRows
      refNode.scrollTop = ref.scrollHeight
    }
    
    this.setState({
      rows: currentRows < maxRows ? currentRows : maxRows,
    })
  }
  
  render() {

    const { 
      className,
      id,
      name,
      onChange,
      onClick,
      invalid,
      invalidText,
      helperText,
      grow,
      error,
      ...other } = this.props

    const textareaProps = {
      id,
      onChange: evt => {
        if (!other.disabled) {
          onChange(evt)
        }
      },
      onClick: evt => {
        if (!other.disabled) {
          onClick(evt)
        }
      },
    }

    const helper = helperText ? (
      <div className={styles.helperText}>{helperText}</div>
    ) : null

    const wrapperClasses = cx(styles.textArea, className, { hasErrors: error.length > 0 })

    const input = invalid ? (
      <textarea
        {...other}
        {...textareaProps}
        aria-invalid
        data-invalid
      />
    ) : (
      <textarea 
        {...other} 
        {...textareaProps} 
        rows={this.state.rows}  
        ref={ref => this.ref=ref} />
    )

    return (
      <div className={wrapperClasses}>
        {input}
        {error.length > 0 &&
          <FormError text={error[0]} />
        }
        {helper}
      </div>
    )
  }
}

TextArea.propTypes = {
  /**
   * Provide a custom className that is applied directly to the underlying
   * <textarea> node
   */
  className: PropTypes.string,

  /**
   * Specify the `cols` attribute for the underlying <textarea> node
   */
  cols: PropTypes.number,

  /**
   * Optionally provide the default value of the <textarea>
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Specify whether the control is disabled
   */
  disabled: PropTypes.bool,

  /**
   * Provide a unique identifier for the control
   */
  id: PropTypes.string,

  /**
   * Optionally provide an `onChange` handler that is called whenever <textarea>
   * is updated
   */
  onChange: PropTypes.func,

  /**
   * Optionally provide an `onClick` handler that is called whenever the
   * <textarea> is clicked
   */
  onClick: PropTypes.func,

  /**
   * Specify the placeholder attribute for the <textarea>
   */
  placeholder: PropTypes.string,

  /**
   * Specify the rows attribute for the <textarea>
   */
  rows: PropTypes.number,

  /**
   * Provide the current value of the <textarea>
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  /**
   * Specify whether the control is currently invalid
   */
  invalid: PropTypes.bool,

  /**
   * Provide the text that is displayed when the control is in an invalid state
   */
  invalidText: PropTypes.string,

  /**
   * Provide text that is used alongside the control label for additional help
   */
  helperText: PropTypes.node,
  grow: PropTypes.bool,
  error: PropTypes.array
}

TextArea.defaultProps = {
  grow: false,
  disabled: false,
  onChange: () => {},
  onClick: () => {},
  placeholder: "",
  rows: 4,
  invalid: false,
  invalidText: "",
  helperText: "",
  error: []
}

export default TextArea