/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { signOut } from "api/auth"

import Button from "components/Button"

const SignOutButton = () => {

  const dispatch = useDispatch()
  const { authenticated } = useSelector(
    state => state.auth
  )

  const handleSignOut = e => {
    e.preventDefault()
    dispatch(signOut())
  }

  if(!authenticated) {
    return <Redirect to={`/sign_in}`} />
  }

  return (
    <div className="menu-button">
      <Button
        className="sign-out"
        onClick={handleSignOut}
        label="Sign Out"
      />
    </div>
  )
}

export default SignOutButton
