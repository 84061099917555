import React from "react"
import pluralize from "pluralize"

import Icon from "components/Icon"
import "./TrialStatus.scss"

 const TrialStatus = ({ role, days }) => {

  const trialText = role === "admin"

    ? "Enter your billing information in Settings to ensure continued service!"
    : "Ask you account admin to enter billing information in Settings to ensure continued service!"
  
  return (
    <div className="trial-status">
      <div className="trial-status-icon">
        <Icon name="clock" width="20" height="20" />
      </div>
      <div className="trial-status-text">
        <strong>{`${pluralize("days", days, true)} left in your trial`}</strong>
        <p>{trialText}</p>
      </div>
    </div>
  )
}

export default TrialStatus