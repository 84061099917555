import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect } from "react-router-dom"
import { acceptInvitation } from "api/invitations"
import { usePageView } from "utils/analytics"

import Auth from "routes/Auth"
import Input from "components/Input"
import Button from "components/Button"
import "./Join.scss"

const JoinRoute = props => {

  const dispatch = useDispatch()
  const { authenticated } = useSelector(
    state => state.auth
  )

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState("")
  const [confirm, setConfirm] = useState("")
  const [errors, setErrors] = useState({})

  // TODO: check token validity on load

  const handleSubmit = e => {
    e.preventDefault()
    const token = props.match.params.token
    const options = {
      data: {
        invite: {
          invitation_token: token,
          password,
          password_confirmation: confirm
        }
      }
    }
    
    resetErrors()
    setLoading(true)
    
    dispatch(acceptInvitation(options)).then(() => {
      setLoading(false)
    }).catch(error => {
      setLoading(false)
      setErrors(error.response.data)
      console.error(error.message)
    })
  }

  usePageView("Join")

  if(authenticated) {
    return <Redirect to="/lobby" />
  }

  const resetErrors = () => setErrors({})

  const getErrors = name => {
    return errors[name] || []
  }

  return (
      <Auth>
        <>
          <h3>Set password</h3>
          <form className="auth-form" onSubmit={handleSubmit}>

            <fieldset>
              <Input
                type="password"
                name="password"
                label="Create a new password"
                value={password}
                disabled={loading}
                error={getErrors("password")}
                onChange={e => setPassword(e.target.value)}
              />

              <Input
                type="password"
                name="password_confirmation"
                label="Confirm your new password"
                value={confirm}
                disabled={loading}
                error={getErrors("password_confirmation")}
                onChange={e => setConfirm(e.target.value)}
              />
            </fieldset>

          <Button
            label="Join"
            type="submit"
            loading={loading}
          />

        </form>
      </>
    </Auth>
  )
}
export default JoinRoute
