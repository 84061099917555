import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
  name: "account",
  initialState: {
    lobbies: [],
    users: []
  },
  reducers: {
    setAccount(state, action) {
      return action.payload
    },
    setLobbies(state, action) {
      return { ...state, lobbies: action.payload }
    },
    setUsers(state, action) {
      return { ...state, users: action.payload }
    },
    updateAccountLobby(state, action) {
      const lobbyId = action.payload.id
      const lobbyIndex = state.lobbies.findIndex(l => l.id === lobbyId)
      if(lobbyIndex < 0 ){
        // visit does not yet exist: add
        state.lobbies.push(action.payload)
      } else {
        // visit exists: replace
        state.lobbies.splice(lobbyIndex, 1, action.payload)
      }
    },
    updateAccountUser(state, action) {
      const userId = action.payload.id
      const userIndex = state.users.findIndex(u => u.id === userId)
      if(userIndex < 0 ){
        // visit does not yet exist: add
        state.users.push(action.payload)
      } else {
        // visit exists: replace
        state.users.splice(userIndex, 1, action.payload)
      }
    },
    removeAccountUser(state, action) {
      const userId = action.payload
      const userIndex = state.users.findIndex(u => u.id === userId)
      if(userIndex >= 0) {
        state.users.splice(userIndex, 1)
      }
    },
  }
})

export const { 
  setAccount, 
  setLobbies,
  updateAccountLobby,
  updateAccountUser,
  removeAccountUser
} = authSlice.actions

export default authSlice.reducer