import React from "react"
import { useSelector } from "react-redux"
import { formatPhoneNumber } from "utils/format"

import LobbySwitcher from "components/lobbies/Switcher"
import TrialStatus from "components/TrialStatus"
import SettingsLink from "components/settings/Link"
import ReportsLink from "components/reports/Link"
import SupportButton from "components/SupportButton"
import SignOutButton from "components/SignOutButton"
import "./MobileNav.scss"

const MobileNav = ({ lobby }) => {

  const { currentUser } = useSelector(
    state => state.auth
  )
  const { account, role } = currentUser
  const { status, multiple_lobbies } = account

  const { display_name, phone_number } = lobby

  // TODO: won't have a phone number
  const formattedPhone = formatPhoneNumber(phone_number)

  const active = status === "active" || status === "trialing"
  const showSwitcher = role === "admin" && multiple_lobbies

  return (
    <nav className="mobile-nav">

      <div className="nav-top">
        {showSwitcher 
          ? <LobbySwitcher currentLobbyId={lobby.id} currentLobbyName={lobby.name} />
          : <div className="location-name">
              {display_name}  
            </div>
        }
        {active && <p className="location-number">{`Waitlist number: ${formattedPhone}`}</p>}

        <ul>
          <li>{account.status === "trialing" && <TrialStatus days={account.trial_days_remaining} />}</li>
        </ul>
      </div>
      
      <div className="nav-bottom">
        <ul>
          <li><SupportButton menu={true} /></li>
          <li><ReportsLink /></li>
          {role === "admin" && <li><SettingsLink /></li>}
          <li><SignOutButton /></li>
        </ul>
      </div>
    </nav>
  )
}

export default MobileNav
