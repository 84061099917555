import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { capitalize  } from "utils/format"

import Logo from "components/Logo"
import Icon from "components/Icon"

import "./Sidebar.scss"

const ReportsSidebar = ({ sections, activeSectionIndex, handleMenuClick, active }) => {

  const toggleMenu = () => {
    const sidebar = document.getElementsByClassName("reports-sidebar")[0]
    sidebar.classList.toggle("open")
  }

  return (
    <nav className="reports-sidebar">

      <div className={`menu ${active ? "active" : ""}`}>
        <div className="menu-header">
          <Logo />
          <div className="mobile-close" onClick={toggleMenu}>
            <Icon name="close" width="24" height="24" />
          </div>
        </div>

        <ul>
          {sections.map((section, i) => 
            <li 
              key={section.label} 
              className={classNames(section.class, { active: activeSectionIndex === i})}
              onClick={() => handleMenuClick(section)}
            >
              <span className={section.class}>{capitalize(section.label)}</span>
            </li>
          )}
        </ul>
      </div>
      
      <div className="menu-button">
        <Link className="return" to="/lobby">Return to operator mode</Link>
      </div>
      
    </nav>
  )
}


export default ReportsSidebar
