import { createSlice } from "@reduxjs/toolkit"

const lobbySlice = createSlice({
  name: "lobbies",
  initialState: {
    current: {
      visits: []
    },
    loading: true
  },
  reducers: {
    getLobbyStart(state) {
      state.loading = true
    },
    getLobbySuccess(state) {
      state.loading = false
    },
    setLobby(state, action) {
      state.current = action.payload
    },
    removeVisit(state, action) {
      const visitIndex = state.current.visits.findIndex(v => v.id === action.payload)
      if(visitIndex >= 0) {
        state.current.visits.splice(visitIndex, 1)
      }
    },
    addVisit(state, action) {
      const visitId = action.payload.id
      const visitIndex = state.current.visits.findIndex(v => v.id === visitId)
      if(visitIndex < 0 ){
        // visit does not yet exist: add
        state.current.visits.push(action.payload)
      } else {
        // visit exists: replace
        state.current.visits.splice(visitIndex, 1, action.payload)
      }
    },
    markMessagesAsRead(state, action) {
      const visitorId = action.payload
      const visitIndex = state.current.visits.findIndex(v => v.visitor_id === visitorId)
      if(visitIndex >= 0 ){
        const updatedVisit = state.current.visits[visitIndex]
        updatedVisit.unread_messages = 0
        state.current.visits.splice(visitIndex, 1, updatedVisit)
      }
    }
  }
})

export const { 
  getLobbyStart,
  getLobbySuccess,
  setLobby, 
  removeVisit, 
  addVisit,
  markMessagesAsRead
} = lobbySlice.actions

export default lobbySlice.reducer