import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Redirect, Route, Switch } from "react-router-dom"
import { getAccount } from "api/account"

import Sidebar from "routes/settings/Sidebar"
import AccountRoute from "routes/settings/account/Index"
import UsersRoute from "routes/settings/users/Index"
import UsersInviteRoute from "routes/settings/users/Invite"
import UsersEditRoute from "routes/settings/users/Edit"
import WaitlistsRoute from "routes/settings/waitlists/Index"
import WaitlistsNewRoute from "routes/settings/waitlists/New"
import WaitlistsEditRoute from "routes/settings/waitlists/Edit"

import "./Settings.scss"

 const SettingsRoute = ({ match, location }) => {

  const path = match.path

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAccount()).then(response => {
    }).catch(error => console.error(error.message))
  }, [dispatch])

  return (
    <div className="settings-container">
      
      <Sidebar pathname={location.pathname} />

      <div className="settings-page">
        <Switch>
          <Route exact path={`${path}/account`} component={AccountRoute} />
          <Route exact path={`${path}/users`} component={UsersRoute} />
          <Route exact path={`${path}/users/invite`} component={UsersInviteRoute} />
          <Route exact path={`${path}/users/:id/edit`} component={UsersEditRoute} />
          <Route exact path={`${path}/waitlists`} component={WaitlistsRoute} />
          <Route exact path={`${path}/waitlists/new`} component={WaitlistsNewRoute} />
          <Route exact path={`${path}/waitlists/:id/edit`} component={WaitlistsEditRoute} />
          <Redirect to={`${path}/account`} />
        </Switch>
      </div>
    </div>  
  )
}

export default SettingsRoute 