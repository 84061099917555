import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { usePageView } from "utils/analytics"
import { getLobby, updateLobby } from "api/lobbies"

import Header from "routes/settings/Header"
import Content from "routes/settings/Content"
import LobbyFrom from "components/lobbies/Form"
import SignButton from "components/lobbies/SignButton"
import QRCodeButton from "components/lobbies/QRCodeButton"
import "./Edit.scss"

const EditWaitlistRoute = () => {

  const { id } = useParams()
  const lobbyId = id

  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [waitlist, setWaitlist] = useState({})
  const [updating, setUpdating] = useState(false) // update waitlist
  const [loading, setLoading] = useState(true)    // fetch waitlist
  const [errors, setErrors] = useState({})

  useEffect(() => {
    setLoading(true)

    dispatch(getLobby(lobbyId)).then(response => {
      setWaitlist(response.data)
      setLoading(false)
    })
  }, [dispatch, lobbyId])

  const handleUpdate = params => {
    
    const options = {
      data: {
        lobby: params
      }
    }
    
    resetErrors()
    setUpdating(true)
    
    dispatch(updateLobby(lobbyId, options)).then(() => {
      setUpdating(false)
      addToast("Waitlist updated!", { appearance: "success" })
      history.push("/settings/waitlists")
    }).catch(error => {
      setErrors(error.response.data)
      setUpdating(false)
    })
  }

  usePageView("Edit Waitlist")

  const resetErrors = () => setErrors({})

  const { phone_number } = waitlist
  
  return (
    <div className="edit-waitlist">
      <Header title="Edit waitlist" />
      {!loading && 
        <Content>
        
          <LobbyFrom 
            lobby={waitlist} 
            errors={errors}
            loading={updating}
            handleSubmit={handleUpdate}
          />

          <div className="download-signs">
            <h3>Download Signs</h3>
            <p>
              Download a premade door sign or create your own using your unique 
              QR code to let visitors know how to add themselves to your waitlist.
            </p>
            <p>
              When someone scans the QR code with their smartphone, it will prepopulate 
              an SMS message to your waitlist number, making check-in even easier!
            </p>

            <div className="buttons">
              <SignButton 
                lobbyId={id}
                phoneNumber={phone_number}
              />
              <QRCodeButton 
                lobbyId={id}
                phoneNumber={phone_number}
              />
            </div>
          </div>
        </Content>
      }
    </div>
    
  )
}

export default EditWaitlistRoute
