import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, Redirect } from "react-router-dom"
import queryString from "query-string"
import { registerUser } from "api/auth"
import { usePageView } from "utils/analytics"
import { Helmet } from "react-helmet"

import Auth from "routes/Auth"
import Input from "components/Input"
import FormError from "components/FormError"
import Button from "components/Button"
import SupportButton from "components/SupportButton"
import Icon from "components/Icon"
import "./Auth.scss"

const SignUpRoute = props => {
  const dispatch = useDispatch()
  const { authenticated, currentUser } = useSelector(
    state => state.auth
  )

  const search = props.location.search
  const qs = queryString.parse(search)
  const plan = qs.plan

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const [success, setSuccess] = useState(false)
  const [account, setAccount] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [terms, setTerms] = useState(false)

  const resetErrors = () => setErrors({})

  const handleSubmit = e => {
    e.preventDefault()
    const params = {
      email,
      password,
      account_attributes: {
        name: account,
        terms,
        plan
      }
    }

    resetErrors()
    setLoading(true)
    
    dispatch(registerUser(params))
      .then(() => setSuccess(true))
      .catch(error => {
        if(error.response.data){
          setErrors(error.response.data.errors)
        }
        setSuccess(false)
        setLoading(false)
      })
  }

  const getErrors = name => {
    return errors[name] || []
  }

  usePageView("Sign Up")

  if(authenticated && currentUser.confirmed) {
    return <Redirect to="/lobby" />
  }

  const origin = window.location.origin
  const href = `${origin}/sign_up`

  return (

    <Auth className="auth-top">
      <Helmet>
        <link rel="canonical" href={href} />
      </Helmet>
      {success

      ? <div className="success-message">
          <Icon name="success" width="88" height="88" />
          <p>We've created your account.</p>
          <p>
            Check your email inbox (or spam folder) for instructions to 
            confirm and finish setting up your account!
          </p>
        </div>

      : <>
          <p>You’re 2 minutes away from having your own virtual waitlist.</p>
          <form onSubmit={handleSubmit} className="auth-form sign-in-form">

            <Input
              type="text"
              name="account.name"
              label="What is your business's name?"
              value={account}
              disabled={loading}
              error={getErrors("account.name")}
              required
              onChange={e => setAccount(e.target.value)}
            />

            <Input
              type="email"
              name="email"
              label="What is your email address?"
              value={email}
              disabled={loading}
              error={getErrors("email")}
              required
              onChange={e => setEmail(e.target.value)}
            />

            <Input
              type="password"
              name="password"
              label="Create a password"
              value={password}
              disabled={loading}
              error={getErrors("password")}
              required
              onChange={e => setPassword(e.target.value)}
            />

            <div className="terms">
              <label className="checkbox" htmlFor="terms">
                <input
                  id="terms"
                  type="checkbox"
                  name="account.terms"
                  checked={terms}
                  onChange={e => setTerms(!terms)}
                />
                <span>Accept </span>
                <a 
                  href="https://awaiting.app/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a> 
              </label>
              {getErrors("account.terms").length > 0 &&
                <FormError text={getErrors("account.terms")[0]} />
              }
            </div>

            <Button
              label="Start 14 day free trial"
              type="submit"
              loading={loading}
            />

          </form>
          <Link 
            to="/sign_in"
            className="sign-in-link"
          >
            Already have an account?
          </Link>
          <br />
          <SupportButton
            label="Trouble creating an account?"
          />
        </>
      }
    </Auth>
  )
}

export default SignUpRoute
