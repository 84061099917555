import apiCall from "utils/api"
import { signInUser } from "./auth"
  
export const sendInvitation = options => async () => {
  return apiCall("post", `invitation`, options).then(response => {
    return response
  }).catch(error => {
    console.error(error.message)
    throw error
  })
}

export const acceptInvitation = options => async dispatch => {
  return apiCall("patch", `invitation`, options).then(response => {
    const email = response.data.email
    const authOptions = {
      data: {
        email,
        password: options.data.invite.password
      }
    }
    return dispatch(signInUser(authOptions)).then(response => {
      return response
    })
    .catch(error => {
      console.error(error.message)
      throw error
    })
  }).catch(error => {
    console.error(error.message)
    throw error
  })
}