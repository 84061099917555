import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { getUser, updateUser, deleteUser } from "api/users"

import Header from "routes/settings/Header"
import Content from "routes/settings/Content"
import UserForm from "components/users/Form"
import "./Edit.scss"

const UserEditRoute = () => {

  const { id } = useParams()
  const userId = id

  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const [user, setUser] = useState({})
  const [updating, setUpdating] = useState(false) // update user
  const [loading, setLoading] = useState(true)    // fetch user
  const [errors, setErrors] = useState({})

  const { currentUser } = useSelector(
    state => state.auth
  )

  const isEditingCurrentUser = currentUser.id === userId

  useEffect(() => {
    dispatch(getUser(userId)).then(response => {
      setUser(response.data)
      setLoading(false)
    })
  }, [dispatch, userId])

  const handleUpdate = params => {
    
    const options = {
      data: {
        user: params
      }
    }
    
    resetErrors()
    setUpdating(true)
    
    dispatch(updateUser(userId, options)).then(() => {
      setUpdating(false)
      addToast("User updated!", { appearance: "success" })
      history.push("/settings/users")
    }).catch(error => {
      setErrors(error.response.data)
      setUpdating(false)
    })
  }

  const handleDelete = () => {
    if(window.confirm("Are you sure you want to remove this user?")){
      dispatch(deleteUser(userId)).then(() => {
        addToast("User removed!", { appearance: "success" })
        history.push("/settings/users")
      }).catch(error => {
        console.error("Something went wrong")
      })
    }
  }
  
  const resetErrors = () => setErrors({})

  return (
    <div className="edit-user">
      <Header title="Edit user" />
      <Content>
      {!loading &&
        <>
          <UserForm 
            user={user}
            errors={errors}
            loading={updating}
            submitText="Update user"
            roleEdit={!isEditingCurrentUser}
            handleSubmit={handleUpdate}
          />
          {!isEditingCurrentUser &&
            <div className="delete-user">
              <button 
                onClick={handleDelete}
              >
                Remove user
              </button>
            </div>
          }
        </>
      }
      </Content>
    </div>
  )
}

export default UserEditRoute