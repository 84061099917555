import React, { useState } from "react"
import { useSelector } from "react-redux"

import Input from "components/Input"
import Button from "components/Button"
import RoleManager  from "components/users/RoleManager"
import "./Form.scss"

const UserForm = ({ 
  user, 
  errors, 
  loading, 
  submitText,
  roleEdit,
  handleSubmit 
}) => {

  const [name, setName] = useState(user.name)
  const [email, setEmail] = useState(user.email)
  const [role, setRole] = useState(user.role || "admin")
  const [currentLobbyId, setCurrentLobbyId] = useState(user.current_lobby_id)

  const { lobbies } = useSelector(
    state => state.account
  )

  if(!currentLobbyId && lobbies.length) {
    setCurrentLobbyId(lobbies[0].id)
  }

  const prepareData = e => {
    e.preventDefault()
    const params = {
      name,
      email,
      role,
      current_lobby_id: currentLobbyId
    }

    handleSubmit(params)
  }

  const getErrors = name => {
    return errors[name] || []
  }

  return (
    <form className="user-form" onSubmit={prepareData}>
      <fieldset>
        <Input
          type="text"
          name="name"
          label="Name"
          value={name}
          disabled={loading}
          error={getErrors("name")}
          onChange={e => setName(e.target.value)}
        />

        <Input
          type="email"
          name="email"
          label="Email address"
          value={email}
          disabled={loading}
          error={getErrors("email")}
          onChange={e => setEmail(e.target.value)}
        />

        <RoleManager 
          editable={roleEdit}
          lobbies={lobbies} 
          role={role} 
          handleRoleChange={setRole}
          handleCurrentLobbyChange={setCurrentLobbyId}
          currentLobbyId={currentLobbyId}
        />
      </fieldset>

      <Button
        label={submitText}
        type="submit"
        loading={loading}
      />

    </form>
  )
}

export default UserForm
